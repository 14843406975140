<template>
  <div id="container" v-if="title">
    <section class="section">
      <div class="container">
        <Panel max-width="1260px">
          <StaticInterview
            class="margin-xxl"
            :title="title"
            :headlineHtml="headlineHtml"
            :illustrationUrl="illustrationUrl"
            :projectName="projectName"
            :projectUrl="projectUrl"
            :actionName="actionName"
            :actionUrl="actionUrl"
            :interviewHtml="interviewHtml"
            :matchingRoundNumber="matchingRound.number"
            :matchingRoundCreatedDate="matchingRound.createdDate"
            emphasize
          />

          <div class="columns is-multiline margin-xl">
            <div class="column">
              <Panel padding-sm>
                <div class="has-text-centered">
                  <div
                    class="is-size-5 has-text-centered has-text-weight-semibold margin-lg"
                  >
                    Meet more people like
                    {{ firstName }} 🤝
                  </div>
                  <div class="is-inline-block has-text-left margin-lg">
                    <div class="margin-md">
                      1. Sign up
                    </div>
                    <div class="margin-md">
                      2. Receive a weekly email introduction
                    </div>
                    <div class="margin-md">
                      3. Enjoy effortless networking 🌴🍹
                    </div>
                  </div>

                  <div
                    class="has-text-weight-semibold has-text-justified margin-xl"
                  >
                    Go beyond your usual social circle, start meeting inspiring
                    people and watch the doors open.
                  </div>

                  <router-link
                    :to="{ name: 'signup' }"
                    @click.native="trackSignupClick"
                  >
                    <b-button
                      class="is-medium is-primary"
                      id="header-signup-button"
                    >
                      Sign up
                    </b-button>
                  </router-link>
                </div>
              </Panel>
            </div>

            <div class="column">
              <Panel padding-sm min-height="9rem">
                <div class="has-text-centered">
                  <div class="is-size-5 margin-lg has-text-weight-semibold">
                    Share 🙌
                  </div>
                  <social-sharing inline-template @open="trackShareClick">
                    <div>
                      <network network="twitter">
                        <b-button
                          class="is-twitter"
                          icon-left="twitter"
                          icon-pack="fab"
                          outlined
                        >
                          Twitter
                        </b-button>
                      </network>
                      <network network="facebook">
                        <b-button
                          class="is-facebook"
                          icon-left="facebook"
                          icon-pack="fab"
                          outlined
                        >
                          Facebook
                        </b-button>
                      </network>
                      <network network="reddit">
                        <b-button
                          class="is-reddit"
                          icon-left="reddit"
                          icon-pack="fab"
                          outlined
                        >
                          Reddit
                        </b-button>
                      </network>
                      <network network="linkedin">
                        <b-button
                          class="is-linkedin"
                          icon-left="linkedin"
                          icon-pack="fab"
                          outlined
                        >
                          LinkedIn
                        </b-button>
                      </network>
                    </div>
                  </social-sharing>
                </div>
              </Panel>
            </div>
          </div>

          <AccountProfile
            :firstName="firstName"
            :lastName="lastName"
            :email="email"
            :contact="contact"
            :pictureUrl="pictureUrl"
            :currentTitle="currentTitle"
            :currentCompany="currentCompany"
            :currentPlaceDesc="currentPlaceDesc"
            showBy
          />
        </Panel>
      </div>
    </section>
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import Panel from "@/atoms/Panel.vue";
import AccountProfile from "@/organisms/AccountProfile.vue";
import StaticInterview from "@/organisms/StaticInterview.vue";

export default {
  name: "StaticInterviewLandingPage",
  components: {
    Panel,
    AccountProfile,
    StaticInterview
  },
  async mounted() {
    if (this.$route.params.titleSlug !== this.titleSlug) {
      await this.$store.dispatch({
        type: "staticInterviewManagement/staticInterview/reset"
      });
    }

    this.generalTitleSlug = this.$route.params.titleSlug;

    this.$store.dispatch({
      type: "staticInterviewManagement/getStaticInterviewByTitleSlug"
    });

    this.$ga.page(this.$router);
  },
  computed: {
    ...mapFields("staticInterviewManagement", {
      generalTitleSlug: "titleSlug"
    }),
    ...mapFields("staticInterviewManagement/staticInterview", [
      "title",
      "titleSlug",
      "headlineHtml",
      "illustrationUrl",
      "projectName",
      "projectUrl",
      "actionName",
      "actionUrl",
      "interviewHtml",
      "matchingRound"
    ]),
    ...mapFields("staticInterviewManagement/account", [
      "firstName",
      "lastName",
      "email",
      "contact",
      "pictureUrl"
    ]),
    ...mapFields("staticInterviewManagement/accountProfile", [
      "currentTitle",
      "currentCompany"
    ]),
    ...mapFields("staticInterviewManagement/accountProfile", {
      currentPlaceDesc: "currentPlace.description"
    })
  },
  methods: {
    trackSignupClick() {
      this.$ga.event("signup_link", "click", "project_landing_page_footer");
    },
    trackShareClick(label) {
      this.$ga.event("share_button", "click", `project_landing_page_${label}`);
    }
  },
  data: () => ({
    show: false
  })
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height;
  margin-bottom: 12rem;
}
.separator {
  margin: 2rem 0;
  border-bottom: 1px solid rgb(230, 230, 230);
}
</style>
