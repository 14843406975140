import { getField as _getField, mapFields as _mapFields, mapMultiRowFields as _mapMultiRowFields } from "vuex-map-fields";
import Vue from "vue"

export const getField = _getField
export const mapFields = _mapFields
export const mapMultiRowFields = _mapMultiRowFields

export const updateField = function (state, _ref3) {
	var path = _ref3.path,
		value = _ref3.value;
	path.split(/[.[\]]+/).reduce(function (prev, key, index, array) {
		if (array.length === index + 1) {
			// eslint-disable-next-line no-param-reassign
			Vue.set(prev, key, value);
		}

		return prev[key];
	}, state);
}
