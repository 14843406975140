import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from 'vuex-persist'
import Router from "@/router"

import Authentication from "@/services/authentication"
import AccountManagement from "@/services/account-management"
import StaticInterviewManagement from "@/services/static-interview-management"
import Localization from "@/services/localization"
import Routing from "@/services/routing"
import LandingPage from "@/services/landing-page"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    authentication: {
      token: state.authentication.token
    }
  })
})

export default class extends Vuex.Store {
  constructor(config, webserverCli, userMatcherCli) {
    super({
      strict: config.strictMode,
      modules: {
        // Services
        authentication: new Authentication(),
        accountManagement: new AccountManagement(),
        staticInterviewManagement: new StaticInterviewManagement(),
        localization: new Localization(),
        routing: new Routing(),
        landingPage: new LandingPage(),
      },
      getters: {
        loggedIn: (state, getters) => {
          return getters["authentication/loggedIn"]
        },
        inFlight: (state, getters) => {
          return getters["authentication/inFlight"] ||
            getters["accountManagement/inFlight"] ||
            getters["staticInterviewManagement/inFlight"] ||
            getters["localization/inFlight"] ||
            getters["routing/inFlight"] ||
            getters["landingPage/inFlight"]
        },
        errors: (state, getters) => {
          return [
            ...getters["authentication/errors"],
            ...getters["accountManagement/errors"],
            ...getters["staticInterviewManagement/errors"],
            ...getters["localization/errors"],
            ...getters["routing/errors"],
            ...getters["landingPage/errors"]
          ]
        },
      },
      actions: {
        reset({
          dispatch
        }) {
          dispatch("authentication/reset")
          dispatch("accountManagement/reset")
          dispatch("staticInterviewManagement/reset")
          dispatch("localization/reset")
          dispatch("routing/reset")
          dispatch("landingPage/reset")
        },

        cleanupErrors({
          commit
        }) {
          commit("authentication/cleanupErrors")
          commit("accountManagement/cleanupErrors")
          commit("staticInterviewManagement/cleanupErrors")
          commit("localization/cleanupErrors")
          commit("routing/cleanupErrors")
          commit("landingPage/cleanupErrors")
        },
      },
      plugins: [vuexLocal.plugin]
    })
    this.router = new Router(this)
    this.userMatcherCli = userMatcherCli
    this.webserverCli = webserverCli
  }
}
