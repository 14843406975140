<template>
  <div class="has-text-left">
    <CheckboxButtons
      label="You would like to meet"
      :options="decoratedPersonaAspirationList"
      v-model="personaAspirations"
    />
    <CheckboxButtons
      label="Specialized in"
      :options="decoratedFocusAspirationList"
      v-model="focusAspirations"
      v-if="decoratedFocusAspirationList.length"
    />
    <CityPicker />
    <div style="padding-top:0.3rem; font-size:13px">
      <span
        style="margin:0 0.2rem"
        v-for="c in decoratedCityList"
        :key="c.placeId"
      >
        <a @click="cityClick(c)" v-if="!c.path">
          {{ c.name }}
        </a>
        <router-link :to="{ path: c.path }" v-if="c.path">
          {{ c.name }}
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "@/lib/vuex-map-fields";
import CheckboxButtons from "@/atoms/CheckboxButtons.vue";
import CityPicker from "@/molecules/CityPicker.vue";

export default {
  name: "AccountMatchingFormLookingFor",
  components: {
    CheckboxButtons,
    CityPicker
  },
  props: {
    computeUrlNextSteps: Boolean
  },
  mounted() {},
  watch: {
    urlNextSteps: {
      immediate: true,
      async handler() {
        if (!this.computeUrlNextSteps) {
          return;
        }
        await Promise.all([
          this.$store.dispatch({
            type: "landingPage/getNextRoutes"
          }),
          this.$store.dispatch({
            type: "landingPage/listTopAccounts"
          })
        ]);
      }
    }
  },
  computed: {
    ...mapFields("accountManagement/accountProfile", [
      "personaAspirations",
      "focusAspirations"
    ]),
    ...mapGetters("landingPage", [
      "urlNextSteps",
      "decoratedPersonaAspirationList",
      "decoratedFocusAspirationList",
      "decoratedCityList"
    ]),
    ...mapFields("accountManagement/accountProfile", {
      currentPlaceId: "currentPlace.id",
      currentPlaceDesc: "currentPlace.description"
    })
  },
  methods: {
    cityClick(city) {
      this.currentPlaceId = city.placeId;
      this.currentPlaceDesc = city.description;
    }
  }
};
</script>

<style scoped>
</style>
