import Vue from "vue"
import Buefy from "buefy"
import VueAnalytics from 'vue-analytics'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faFacebook, faLinkedin, faReddit } from '@fortawesome/free-brands-svg-icons'
import { faUser, faEnvelope, faPaperPlane, faMagic, faUserSecret, faExclamationCircle, faBuilding, faSuitcase, faHome, faStar, faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from "./App.vue"
import Store from "./store"
import HTTPCli from "@/lib/http-cli";
import WebserverCli from "@/lib/webserver-cli";
import UserMatcherCli from "@/lib/user-matcher-cli";
import getConfig from "./config";

Vue.config.productionTip = false

const cfg = getConfig(process.env.NODE_ENV)

// Font Awesome
library.add(faTwitter, faFacebook, faLinkedin, faReddit)
library.add(faUser, faEnvelope, faPaperPlane, faMagic, faUserSecret, faExclamationCircle, faBuilding, faSuitcase, faHome, faStar, faGlobe, faMapMarkerAlt)
Vue.component("font-awesome-icon", FontAwesomeIcon)

// Buefy
Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
})

// Social sharing
var SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);

// Google Analytics
Vue.use(VueAnalytics, {
  id: cfg.analyticsID,
  autoTracking: {
    exception: true,
  },
  debug: {
    enabled: process.env.NODE_ENV !== "production",
    sendHitTask: process.env.NODE_ENV === "production",
  },
})

const httpWebserverCli = new HTTPCli("")
const webserverCli = new WebserverCli(httpWebserverCli)

const httpAPICli = new HTTPCli(cfg.graphqlServerUrl)
const userMatcherCli = new UserMatcherCli(httpAPICli)

const store = new Store(cfg, webserverCli, userMatcherCli)

Vue.prototype.$config = cfg

new Vue({
  store: store,
  router: store.router,
  render: h => h(App),
}).$mount("#app")
