<template>
  <div class="has-text-centered">
    <b-field>
      <b-input
        v-model="firstName"
        icon="user"
        placeholder="First name"
        required
        ref="firstName"
        @keyup.enter.native="signup"
      />
    </b-field>
    <b-field>
      <b-input
        v-model="lastName"
        icon="user"
        placeholder="Last name (optional)"
        @keyup.enter.native="signup"
      />
    </b-field>
    <b-field>
      <b-input
        v-model="email"
        icon="envelope"
        type="email"
        placeholder="Email address"
        required
        ref="email"
        @keyup.enter.native="signup"
      />
    </b-field>

    <div class="margin-lg has-text-centered" id="signup-button-container">
      <button class="button is-medium is-primary" @click="signup">
        Start networking
      </button>
    </div>
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "SignupForm",
  components: {},
  props: {
    trackingLabel: String
  },
  computed: {
    isValid() {
      return this.$refs.firstName.isValid && this.$refs.email.isValid;
    },
    ...mapFields("accountManagement/account", [
      "firstName",
      "lastName",
      "email"
    ])
  },
  methods: {
    checkHtml5Validity() {
      this.$refs.firstName.checkHtml5Validity();
      this.$refs.email.checkHtml5Validity();
    },
    signup() {
      this.checkHtml5Validity();
      if (!this.isValid) {
        Toast.open({
          duration: 3000,
          message: "Oops. Looks like some mandatory information is missing.",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }

      this.$ga.event("signup", "click", this.trackingLabel);

      this.$store.dispatch({
        type: "authentication/signup"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#signup-button-container {
  padding-top: 1rem;
}
</style>
