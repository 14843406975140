<template>
  <div class="account-contact-selector">
    <div class="label">
      {{ label }}
    </div>
    <div :key="o.id" v-for="o in decoratedOptions">
      <b-checkbox
        :value="o.selected"
        @input="selectionHandler(o.id, $event)"
        v-if="selectionEnabled"
      >
        {{ o.name }}
      </b-checkbox>

      <span class="name-span margin-sm" v-if="!selectionEnabled">
        {{ o.name }}
      </span>

      <b-field
        class="is-hidden-mobile"
        v-if="
          o.externalIdPlaceholder &&
            inputEnabled &&
            (!selectionEnabled || o.selected || o.externalId)
        "
        expanded
      >
        <p class="control">
          <span class="button is-static">{{ o.externalIdDescription }}</span>
        </p>
        <b-input
          :class="{
            'external-id-input': !selectionEnabled,
            'external-id-input-selection': selectionEnabled
          }"
          :placeholder="o.externalIdPlaceholder"
          :value="o.externalId"
          @input="externalIdHandler(o.id, $event)"
          expanded
        />
      </b-field>

      <div
        class="is-hidden-tablet"
        v-if="
          o.externalIdPlaceholder &&
            inputEnabled &&
            (!selectionEnabled || o.selected || o.externalId)
        "
      >
        <p class="control">
          <span class="description-mobile button is-static">{{
            o.externalIdDescription
          }}</span>
        </p>
        <b-input
          :class="{
            'external-id-input': !selectionEnabled,
            'external-id-input-selection': selectionEnabled
          }"
          :placeholder="o.externalIdPlaceholder"
          :value="o.externalId"
          @input="externalIdHandler(o.id, $event)"
          expanded
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountContactSelector",
  props: {
    label: String,
    options: Array,
    value: Object,
    selectionValue: Array,
    selectionEnabled: Boolean,
    inputEnabled: Boolean
  },
  mounted() {
    if (this.selectionValue) {
      this.refreshSelectionMap(this.selectionValue);
    }
  },
  methods: {
    selectionHandler(id, value) {
      this.$set(this.selectionMap, id, value);
      const selection = Object.keys(this.selectionMap).filter(
        c => this.selectionMap[c] === true
      );
      this.$emit("selectionInput", selection);
    },
    externalIdHandler(id, value) {
      let newValue = { ...this.value };
      newValue[id] = value;
      this.$emit("input", newValue);
    },
    refreshSelectionMap(selectionValue) {
      this.selectionMap = selectionValue.reduce(function(
        result,
        optionSelection
      ) {
        result[optionSelection] = true;
        return result;
      },
      {});
    }
  },
  computed: {
    decoratedOptions() {
      return this.options.map(option => {
        return {
          ...option,
          selected: this.selectionMap[option.id] ? true : false,
          externalId: this.value[option.id] ? this.value[option.id] : ""
        };
      });
    }
  },
  watch: {
    selectionValue(newValue) {
      this.refreshSelectionMap(newValue);
    }
  },
  data: () => ({
    selectionMap: {}
  })
};
</script>

<style scoped>
.account-contact-selector /deep/ .field {
  margin-bottom: 0;
}
.external-id-input {
  margin-bottom: 1rem;
}
.external-id-input-selection {
  margin-bottom: 1rem;
}
.name-span {
  display: inline-flex;
  align-items: center;
  line-height: 1.25;
}
.description-mobile {
  width: 100%;
}
</style>
