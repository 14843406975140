<template>
  <div class="checkbox-buttons">
    <div class="label">
      {{ label }}
      <span class="has-text-grey has-text-weight-light" v-if="optional">
        (optional)
      </span>
    </div>
    <b-field>
      <span v-for="o in options" :key="o.id">
        <router-link
          :to="{ path: o.path }"
          @click.native="routerClick(o.id)"
          v-if="o.path"
        >
          <b-checkbox-button
            :value="value"
            :native-value="o.id"
            :ref="o.id"
            @input="$emit('input', $event)"
          >
            <b-tooltip
              :label="o.description"
              position="is-top"
              type="is-dark"
              animated
              dashed
              multilined
              size="is-small"
              v-if="o.description"
              style="cursor: pointer"
            >
              {{ o.name }}
            </b-tooltip>
            <span v-if="!o.description">{{ o.name }}</span>
          </b-checkbox-button>
        </router-link>

        <b-checkbox-button
          :value="value"
          :native-value="o.id"
          :ref="o.id"
          @input="$emit('input', $event)"
          v-if="!o.path"
        >
          <b-tooltip
            :label="o.description"
            position="is-top"
            type="is-dark"
            animated
            dashed
            multilined
            size="is-small"
            v-if="o.description"
            style="cursor: pointer"
          >
            {{ o.name }}
          </b-tooltip>
          <span v-if="!o.description">{{ o.name }}</span>
        </b-checkbox-button>
      </span>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "CheckboxButtons",
  props: {
    label: String,
    options: Array,
    value: Array,
    optional: Boolean
  },
  methods: {
    routerClick(id) {
      let newValue = [...this.value];

      if (this.$refs[id][0].$refs.input.checked) {
        newValue = newValue.filter(i => i !== id);
      } else {
        newValue.push(id);
      }

      this.$emit("input", newValue);
    }
  }
};
</script>

<style scoped>
</style>
