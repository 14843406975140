<template>
  <div>
    <section class="hero" id="header-hero">
      <div class="hero-body">
        <div class="container">
          <p
            class="has-text-left is-size-7"
            v-if="filteredBreadCrumbs.length !== 0"
          >
            <span v-for="c in filteredBreadCrumbs" :key="c.position">
              <router-link :to="{ path: c.item }">{{ c.name }}</router-link>
              >
            </span>
            <span>{{ name }}</span>
          </p>
          <div id="text-container">
            <img src="/static/heading.svg" id="heading-img" draggable="false" />

            <h1
              class="is-size-2 is-size-3-mobile has-text-weight-semibold text-compact margin-md"
            >
              {{ headline }}
            </h1>

            <h2
              class="is-size-3 is-size-4-mobile text-compact has-text-left is-inline-block"
            >
              Get matched to a fellow tech enthusiast, read about their story,
              meet over an email or a coffee
            </h2>

            <div
              class="divider"
              style="margin-top:1.8rem; margin-bottom:2.4rem;"
              v-if="accounts.length > 0"
            />
            <div
              class="columns is-centered is-multiline is-mobile is-variable is-5-desktop"
              v-if="accounts.length > 0"
            >
              <div
                class="column is-3-desktop is-6-touch"
                :key="a.id"
                v-for="a in accounts"
              >
                <AccountProfile
                  size="small"
                  :firstName="a.firstName"
                  :pictureUrl="a.pictureUrl"
                  :currentPlaceDesc="a.accountProfile.currentPlace.description"
                  :currentTitle="a.accountProfile.currentTitle"
                  :currentCompany="a.accountProfile.currentCompany"
                  :interviewText="a.accountProfile.interviewText"
                />
              </div>
            </div>

            <div class="columns" style="margin-top:3rem;" v-if="!loggedIn">
              <div class="column is-8 is-offset-2">
                <SignupFunnel
                  trackingLabel="landing_page"
                  computeUrlNextSteps
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero" id="details-hero">
      <div class="hero-body">
        <div class="container">
          <div
            class="columns is-desktop is-vcentered"
            style="margin-bottom: 5rem;"
          >
            <div class="column">
              <img
                src="/static/screenshot.png"
                id="intro-email-img"
                draggable="false"
              />
            </div>
            <div class="column is-size-5 is-size-6-mobile">
              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                💡 Get inspired by successful stories
              </div>
              <div class="has-text-justified margin-xxxl">
                Member profiles are designed as self-interviews. From there, you
                will be able to learn more about your match, their story and
                projects.
              </div>

              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                🌱 The best way to learn and grow
              </div>
              <div class="has-text-justified margin-xxxl">
                Our algorithm matches users based on their profile information,
                trying to find the best mutual fit. We're aiming at pairing
                people that can help each other.
              </div>

              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                🌴🍹 Let's keep it chill
              </div>
              <div class="has-text-justified margin-xxxl">
                Actively looking for business partners doesn't work. Let's just
                enjoy a good ranting with a fellow hacker and learn from their
                experience. Time will do the rest.
              </div>

              <router-link
                :to="{ name: 'signup' }"
                @click.native="trackSignupClick('details')"
                v-if="!loggedIn"
              >
                <b-button class="is-medium is-primary" id="">
                  Get started
                </b-button>
              </router-link>
            </div>
          </div>

          <div class="columns is-desktop is-variable is-1">
            <div class="column">
              <div
                class="is-size-5 is-size-6-mobile has-text-grey-dark margin-sm"
              >
                Stay updated
              </div>

              <a href="https://twitter.com/TryFrenl" target="_blank">
                <b-button
                  class="is-twitter"
                  icon-left="twitter"
                  icon-pack="fab"
                  size="is-small"
                >
                  <strong>Follow @TryFrenl</strong>
                </b-button>
              </a>
            </div>
            <div class="column share-block">
              <div class="is-size-5 is-size-6-mobile has-text-grey-dark">
                Share
              </div>
              <social-sharing inline-template @open="trackShareClick">
                <div>
                  <network network="twitter">
                    <b-button
                      class="is-twitter"
                      icon-left="twitter"
                      icon-pack="fab"
                      outlined
                    >
                      Twitter
                    </b-button>
                  </network>
                  <network network="facebook">
                    <b-button
                      class="is-facebook"
                      icon-left="facebook"
                      icon-pack="fab"
                      outlined
                    >
                      Facebook
                    </b-button>
                  </network>
                  <network network="linkedin">
                    <b-button
                      class="is-linkedin"
                      icon-left="linkedin"
                      icon-pack="fab"
                      outlined
                    >
                      LinkedIn
                    </b-button>
                  </network>
                </div>
              </social-sharing>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero has-text-white" id="interview-hero">
      <div class="hero-body">
        <div class="container">
          <div>
            <div
              class="is-size-3 is-size-4-mobile text-compact"
              style="margin-bottom: 3rem;"
            >
              Last interviews
            </div>
            <div class="columns is-multiline">
              <div
                class="column"
                :key="i.id"
                v-for="i in staticInterviews.slice(0, 3)"
              >
                <router-link
                  :to="{
                    name: 'interviewLandingPage',
                    params: { titleSlug: i.titleSlug }
                  }"
                >
                  <Panel paddingSm clickable maxWidth="20rem">
                    <div class="has-text-grey has-text-left">
                      Round {{ i.matchingRound.number }} -
                      {{ i.matchingRound.createdDate }}
                    </div>

                    <div
                      class="is-size-5 has-text-weight-semibold text-compact"
                    >
                      Showing
                      <a :href="i.projectUrl" target="_blank">
                        {{ i.projectName }}</a
                      >
                    </div>
                    <div class="margin-md" v-if="i.title">
                      {{ i.title }}
                    </div>

                    <div
                      class="columns is-mobile is-variable is-1 is-vcentered margin-xs"
                    >
                      <div class="column is-narrow">
                        <Picture
                          :imageSrc="i.account.pictureUrl"
                          size="50px"
                          borderSize="2px"
                          borderRadius="5px"
                        />
                      </div>
                      <div
                        class="column has-text-left has-text-weight-semibold"
                      >
                        <span class="has-text-weight-normal">by </span
                        >{{ i.account.firstName }}
                        {{ i.account.lastName }}
                      </div>
                    </div>

                    <div class="has-text-centered">
                      <router-link
                        class="button is-primary"
                        :to="{
                          name: 'interviewLandingPage',
                          params: { titleSlug: i.titleSlug }
                        }"
                      >
                        📖 Read the full interview
                      </router-link>
                    </div>
                  </Panel>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapFields, mapMultiRowFields } from "@/lib/vuex-map-fields";
import { personas } from "@/enums/personas";
import Panel from "@/atoms/Panel.vue";
import Picture from "@/atoms/Picture.vue";
import SignupFunnel from "@/organisms/SignupFunnel.vue";
import AccountProfile from "@/organisms/AccountProfile.vue";

export default {
  name: "LandingPage",
  components: {
    Panel,
    Picture,
    SignupFunnel,
    AccountProfile
  },
  mounted() {
    this.$store.dispatch({
      type: "staticInterviewManagement/listStaticInterviews"
    });
    this.$ga.page(this.$router);
  },
  computed: {
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    headline() {
      return this.title
        ? "☕️ " + this.title.replace(" | Frenl", "")
        : "☕️ Meet the local startup scene";
    },
    filteredBreadCrumbs() {
      const breadCrumbs = [];
      if (!this.breadCrumbs) {
        return breadCrumbs;
      }
      for (const item of this.breadCrumbs.itemListElement) {
        if (!item.item) {
          continue;
        }
        const newItem = { ...item };
        newItem.item = new URL(newItem.item).pathname;
        breadCrumbs.push(newItem);
      }
      return breadCrumbs;
    },
    ...mapFields("routing/currentRoute", ["name", "title", "breadCrumbs"]),
    ...mapMultiRowFields("staticInterviewManagement/staticInterviews", [
      "staticInterviews"
    ]),
    ...mapMultiRowFields("landingPage/topAccounts", ["accounts"]),
    ...mapFields("accountManagement/accountProfile", [
      "personaAspirations",
      "focusAspirations"
    ]),
    ...mapFields("accountManagement/accountProfile", {
      currentPlaceId: "currentPlace.id",
      currentPlaceDesc: "currentPlace.description"
    })
  },
  methods: {
    trackSignupClick(label) {
      this.$ga.event("signup_link", "click", label);
    },
    trackShareClick(label) {
      this.$ga.event("share_button", "click", label);
    }
  },
  data: () => ({
    personaList: Object.values(personas)
  })
};
</script>

<style lang="scss" scoped>
#header-hero {
  padding: ($nav-bar-height - 1rem) 0 0;
  background: white;
  border-bottom: 1px solid #d3d3d3;
}
#header-hero .hero-body {
  padding-bottom: 2.2rem;
}
#text-container {
  margin: 0.7rem 0 1rem;
}
#heading-img {
  max-height: 13rem;
  margin-bottom: 1.2rem;
}
.social-link {
  margin: 0.4rem;
  color: #3636367a;
}
.social-link:hover {
  color: #363636a2;
}
.share-button {
  margin: 10px;
}

#details-hero {
  margin: 2.5rem 0 1.5rem 0;
}
#intro-email-img {
  height: auto;
  max-width: 100%;
  padding: 0 2rem;
}
@media only screen and (max-width: $tablet) {
  #intro-email-img {
    padding: 0;
  }
}
@media only screen and (min-width: $tablet) and (max-width: $desktop) {
  #intro-email-img {
    max-width: 80%;
  }
}

#interview-hero {
  background: linear-gradient(
    to bottom right,
    $accent-color-1,
    $accent-color-2
  );
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2),
    inset 0 85px 50px rgba(0, 0, 0, 0.025);
  border-top: 1px solid #d3d3d3;
  padding: 1rem 0 4rem 0;
}
</style>
