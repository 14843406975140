var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-contact-selector"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._l((_vm.decoratedOptions),function(o){return _c('div',{key:o.id},[(_vm.selectionEnabled)?_c('b-checkbox',{attrs:{"value":o.selected},on:{"input":function($event){return _vm.selectionHandler(o.id, $event)}}},[_vm._v(" "+_vm._s(o.name)+" ")]):_vm._e(),(!_vm.selectionEnabled)?_c('span',{staticClass:"name-span margin-sm"},[_vm._v(" "+_vm._s(o.name)+" ")]):_vm._e(),(
        o.externalIdPlaceholder &&
          _vm.inputEnabled &&
          (!_vm.selectionEnabled || o.selected || o.externalId)
      )?_c('b-field',{staticClass:"is-hidden-mobile",attrs:{"expanded":""}},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static"},[_vm._v(_vm._s(o.externalIdDescription))])]),_c('b-input',{class:{
          'external-id-input': !_vm.selectionEnabled,
          'external-id-input-selection': _vm.selectionEnabled
        },attrs:{"placeholder":o.externalIdPlaceholder,"value":o.externalId,"expanded":""},on:{"input":function($event){return _vm.externalIdHandler(o.id, $event)}}})],1):_vm._e(),(
        o.externalIdPlaceholder &&
          _vm.inputEnabled &&
          (!_vm.selectionEnabled || o.selected || o.externalId)
      )?_c('div',{staticClass:"is-hidden-tablet"},[_c('p',{staticClass:"control"},[_c('span',{staticClass:"description-mobile button is-static"},[_vm._v(_vm._s(o.externalIdDescription))])]),_c('b-input',{class:{
          'external-id-input': !_vm.selectionEnabled,
          'external-id-input-selection': _vm.selectionEnabled
        },attrs:{"placeholder":o.externalIdPlaceholder,"value":o.externalId,"expanded":""},on:{"input":function($event){return _vm.externalIdHandler(o.id, $event)}}})],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }