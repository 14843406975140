<template>
  <div id="container">
    <MessageBox message="Verifying your account..." />
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import MessageBox from "@/organisms/MessageBox.vue";

export default {
  name: "VerifyAccount",
  components: { MessageBox },
  mounted() {
    this.$ga.page(this.$router);

    new Promise(resolve => {
      setTimeout(resolve, 1000);
    }).then(() => {
      this.$store.dispatch("authentication/logout");
      this.verifyAccountToken = this.$route.query.t;
      return this.$store.dispatch({ type: "authentication/verifyAccount" });
    });
  },
  computed: {
    ...mapFields("authentication", ["verifyAccountToken"])
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height;
  margin-bottom: 12rem;
}
</style>
