<template>
  <Panel :maxWidth="maxWidth">
    <div class="margin-xxl">
      <div class="has-text-centered">
        <Picture
          :imageSrc="imageSrc"
          size="100px"
          borderSize="5px"
          borderRadius="8px"
        />
      </div>
      <div class="label" v-if="!hideName">
        Name
      </div>
      <b-field grouped v-if="!hideName">
        <b-input
          placeholder="First name"
          icon="user"
          expanded
          v-model="firstName"
          ref="firstName"
        />
        <b-input
          placeholder="Last name"
          icon="user"
          expanded
          v-model="lastName"
        />
      </b-field>

      <div class="label">
        Current position
      </div>
      <b-field grouped>
        <b-input
          placeholder="Title"
          icon="suitcase"
          expanded
          v-model="currentTitle"
        />
        <span style="margin: 0.3rem 0.8rem 0 0">at</span>
        <b-input
          placeholder="Company"
          icon="building"
          expanded
          v-model="currentCompany"
        />
      </b-field>
    </div>

    <AccountSocialForm />
  </Panel>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";

import Panel from "@/atoms/Panel.vue";
import AccountSocialForm from "@/molecules/AccountSocialForm.vue";
import Picture from "@/atoms/Picture.vue";

export default {
  name: "AccountProfileForm",
  components: {
    Panel,
    AccountSocialForm,
    Picture
  },
  props: {
    maxWidth: String,
    hideName: Boolean
  },
  computed: {
    isValid() {
      if (this.$refs.firstName) {
        return this.$refs.firstName.isValid;
      }
      return true;
    },
    imageSrc() {
      return this.$store.state.accountManagement.account.pictureUrl;
    },
    ...mapFields("accountManagement/account", ["firstName", "lastName"]),
    ...mapFields("accountManagement/accountProfile", [
      "currentTitle",
      "currentCompany"
    ])
  },
  methods: {
    checkHtml5Validity() {
      if (this.$refs.firstName) {
        this.$refs.firstName.checkHtml5Validity();
      }
    }
  }
};
</script>

<style scoped>
</style>
