<template>
  <div class="location-picker">
    <b-field :label="label">
      <b-autocomplete
        :icon="icon"
        icon-pack="fas"
        :data="autocompletePredictions"
        :placeholder="placeholder"
        field="description"
        :loading="inFlight"
        @typing="typing"
        @select="select"
        v-model="vModel"
      >
        >
        <template slot-scope="props">
          <span>
            <font-awesome-icon class="has-text-grey" icon="map-marker-alt" />
          </span>
          <span style="margin-left:0.2rem">
            {{ props.option.description }}
          </span>
        </template>
        <template slot="empty">
          <span class="has-text-grey">No results found</span>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "LocationPicker",
  props: {
    label: String,
    placeholder: String,
    icon: String,

    inFlight: Boolean,
    autocompletePredictions: Array,
    value: String
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    typing: debounce(async function(location) {
      this.$emit("typing", location);
    }, 400),

    select: function(option) {
      this.$emit("select", option ? option.placeId : "");
    }
  }
};
</script>

<style scoped>
</style>
