<template>
  <div id="container" v-if="id">
    <section class="section">
      <div class="container">
        <div class="margin-xxl">
          <PanelTitle
            title="🔦 Interview highlights"
            :changesSaved="changesSaved"
          />
          <Panel max-width="1260px">
            <div class="margin-xxl" v-if="firstName">
              <div class="margin-xl">
                👋 Hey {{ firstName }}! Welcome to my bleeding edge, in-house
                interviewing system. I built it because I was too lazy to format
                and copy paste emails into my database.
              </div>
              <div class="has-text-weight-semibold margin-lg">
                Quick overview:
              </div>
              <div class="margin-lg">
                - First of all, about the interview itself, none of the
                questions are definitive. If you would like to talk more about a
                certain topic or don't like a question, let's change them. By
                the way, the interview is markdown formatted, your answers are
                autosaved, and you can see the preview at any time at the bottom
                of the page.
              </div>
              <div class="margin-lg">
                - Once you're done with the interview, hit me with an email and
                I'll add the final touch to it. I'll add a title, an intro, some
                illustrations, some quotes... Then I'll recontact you so you can
                double check everything and make sure that you're pleased with
                the result.
              </div>
              <div class="margin-lg">
                - Once validated, I'll flag the interview as "ready" and it will
                get published when the next matching round happens.
              </div>
              <div class="margin-lg">
                - Then, I'll try to promote it on various platforms. First on my
                Twitter and LinkedIn accounts, then on Reddit / Indie Hackers /
                Hacker News / Dev.to / Quora, depending on the interview
                content. I'll communicate you the links if you want to upvote or
                reply to people comments. I cannot promise anything crazy
                regarding traffic, usually we manage to get some hundreds of
                views. Sometimes more, sometimes less.
              </div>
              <div class="margin-lg">
                - That's it! And don't hesitate if you have any questions :)
              </div>
            </div>

            <div class="margin-xxxl">
              <div class="label margin-md">
                🌟 Action to promote
              </div>
              <div class="margin-lg">
                This one allows you to push a particular action at the bottom of
                the interview.
              </div>
              <b-field>
                <b-input
                  placeholder='Action text e.g. "Read our blog post"'
                  expanded
                  v-model="actionName"
                />
              </b-field>
              <b-field>
                <b-input
                  placeholder='Action link e.g. "https://website.com/blog-post"'
                  expanded
                  v-model="actionUrl"
                />
              </b-field>
            </div>

            <div class="margin-xxxl">
              <TextArea
                placeholder="Markdown formatted"
                :height="previewHeight"
                v-model="interviewMd"
              />
            </div>

            <div>Thank you for your time!</div>
          </Panel>
        </div>

        <div>
          <PanelTitle title="Preview" />
          <Panel max-width="1260px">
            <div class="margin-xxl">
              <StaticInterview
                :title="title"
                :headlineHtml="headlineHtml"
                :illustrationUrl="illustrationUrl"
                :projectName="projectName"
                :projectUrl="projectUrl"
                :actionName="actionName"
                :actionUrl="actionUrl"
                :interviewHtml="interviewHtml"
                :matchingRoundNumber="matchingRound.number"
                :matchingRoundCreatedDate="matchingRound.createdDate"
                emphasize
                ref="interviewPreview"
              />
            </div>
            <AccountProfile
              :firstName="firstName"
              :lastName="lastName"
              :email="email"
              :contact="contact"
              :pictureUrl="pictureUrl"
              :currentTitle="currentTitle"
              :currentCompany="currentCompany"
              :currentPlaceDesc="currentPlaceDesc"
              showBy
            />
          </Panel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import debounce from "lodash/debounce";
import Panel from "@/atoms/Panel.vue";
import PanelTitle from "@/atoms/PanelTitle.vue";
import StaticInterview from "@/organisms/StaticInterview.vue";
import AccountProfile from "@/organisms/AccountProfile.vue";
import TextArea from "@/atoms/TextArea.vue";

export default {
  name: "UserStaticInterview",
  components: {
    Panel,
    PanelTitle,
    StaticInterview,
    AccountProfile,
    TextArea
  },
  async mounted() {
    this.$ga.page(this.$router);
    await this.$store.dispatch({
      type: "staticInterviewManagement/getUserStaticInterview"
    });
    this.watchChanges = true;
    this.$nextTick(this.refreshPreviewHeight());
  },
  watch: {
    async userInterviewHash() {
      if (!this.watchChanges) {
        return;
      }
      await this.onInterviewChange();
    }
  },
  methods: {
    onInterviewChange: debounce(async function() {
      await this.$store.dispatch({
        type: "staticInterviewManagement/updateUserStaticInterview"
      });
      await this.$store.dispatch({
        type: "staticInterviewManagement/refreshUserStaticInterviewHtml"
      });
      this.$nextTick(this.refreshPreviewHeight());
    }, 400),

    refreshPreviewHeight() {
      if (this.$refs.interviewPreview) {
        this.previewHeight = this.$refs.interviewPreview.$el.clientHeight;
      }
    }
  },
  computed: {
    userInterviewHash() {
      return this.$store.getters[
        "staticInterviewManagement/userStaticInterview/hash"
      ];
    },
    changesSaved() {
      return this.$store.getters[
        "staticInterviewManagement/userStaticInterviewSaved"
      ];
    },
    ...mapFields("staticInterviewManagement/userStaticInterview", [
      "id",
      "title",
      "titleSlug",
      "headlineHtml",
      "illustrationUrl",
      "projectName",
      "projectUrl",
      "actionName",
      "actionUrl",
      "interviewHtml",
      "interviewMd",
      "matchingRound"
    ]),
    ...mapFields("accountManagement/account", [
      "firstName",
      "lastName",
      "email",
      "contact",
      "pictureUrl"
    ]),
    ...mapFields("accountManagement/accountProfile", [
      "currentTitle",
      "currentCompany"
    ]),
    ...mapFields("accountManagement/accountProfile", {
      currentPlaceDesc: "currentPlace.description"
    })
  },
  data: function() {
    return {
      previewHeight: 0,
      watchChanges: false
    };
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height - 0.5rem;
  margin-bottom: 8rem;
}
</style>
