export const focuses = {
	"backend": {
		id: "backend",
		name: "Back-End",
		persona: "developer"
	},
	"frontend": {
		id: "frontend",
		name: "Front-End",
		persona: "developer"
	},
	"mobileapps": {
		id: "mobileapps",
		name: "Mobile Apps",
		persona: "developer"
	},
	"devops": {
		id: "devops",
		name: "DevOps",
		persona: "developer"
	},
	"machinelearning": {
		id: "machinelearning",
		name: "Machine Learning",
		persona: "developer"
	},

	"growth": {
		id: "growth",
		name: "Growth",
		persona: "maker"
	},
	"sales": {
		id: "sales",
		name: "Sales",
		persona: "maker"
	},
	"seo": {
		id: "seo",
		name: "SEO",
		persona: "maker"
	},
	"monetization": {
		id: "monetization",
		name: "Monetization",
		persona: "maker"
	},
	"nocode": {
		id: "nocode",
		name: "No-Code",
		persona: "maker"
	},
	"funding": {
		id: "funding",
		name: "Funding",
		persona: "maker"
	},


	"uiux": {
		id: "uiux",
		name: "UI/UX",
		persona: "designer"
	},
	"branding": {
		id: "branding",
		name: "Branding",
		persona: "designer"
	},
	"webdesign": {
		id: "webdesign",
		name: "Web Design",
		persona: "designer"
	},
	"illustrations": {
		id: "illustrations",
		name: "Illustrations",
		persona: "designer"
	},
}

export const focusesByPersona = Object.values(focuses).reduce(function (
	result,
	focus
) {
	let focuses = result[focus.persona];
	if (focuses) {
		focuses.push(focus)
	} else {
		focuses = [focus]
	}
	result[focus.persona] = focuses
	return result;
},
	{});
