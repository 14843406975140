import { social } from "@/enums/social.js";
import { communities } from "@/enums/communities";
import { getField, updateField } from "@/lib/vuex-map-fields";
import hash from "object-hash";

function initialState() {
    return {
        firstName: "",
        lastName: "",
        email: "",
        pictureUrl: "",
        contact: {},
        active: false,
        onboarded: false,
    }
}

export default class {
    namespaced = true

    state = initialState()

    getters = {
        getField,
        completenessCount: (state, getters, rootState) => {
            const fillableKeys = rootState.accountManagement.accountProfile.communities.concat(Object.keys(social))
            const completedContacts = Object.entries(state.contact).filter(([key, value]) => fillableKeys.includes(key) && value).length
            return completedContacts
        },
        completenessTotalCount: (state, getters, rootState) => {
            const totalSocialContacts = Object.keys(social).length
            const totalCommunityContacts = rootState.accountManagement.accountProfile.communities.filter(id => communities[id].externalIdDescription ? true : false).length
            return totalSocialContacts + totalCommunityContacts
        },
        hash(state) {
            return hash(state)
        }
    }

    mutations = {
        updateField(state, payload) {
            switch (payload.path) {
                case "contact": {
                    const newContact = { ...state.contact }
                    for (let [key, value] of Object.entries(payload.value)) {
                        newContact[key] = value
                    }
                    state.contact = newContact
                    break;
                }
                default: {
                    updateField(state, payload)
                }
            }
        },
        set(state, payload) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = payload.account[key] ? payload.account[key] : s[key];
            })
        },
        reset(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
    }

    actions = {
        reset({
            commit
        }) {
            commit("reset")
        },
    }
}
