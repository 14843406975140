<template>
  <div id="container">
    <div class="section">
      <Panel maxWidth="22rem">
        <div class="has-text-centered">
          <img
            class="margin-md"
            src="/static/login.svg"
            id="image"
            draggable="false"
          />

          <h1 class="is-size-4 margin-lg has-text-weight-semibold text-compact">
            Log in to your account
          </h1>

          <b-field>
            <b-input
              icon="envelope"
              type="email"
              v-model="loginEmail"
              placeholder="Email address"
              ref="email"
              required
              @keyup.enter.native="login"
            />
          </b-field>

          <div class="margin-lg" id="login-button-container">
            <button class="button is-primary" @click="login">
              Send a magic link
            </button>
          </div>

          <div class="has-text-grey is-size-7">
            New to Frenl?
            <span class="is-inline-block has-text-weight-semibold">
              <router-link :to="{ name: 'signup' }">
                Sign up
              </router-link>
            </span>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import omit from "lodash/omit";
import { mapFields } from "@/lib/vuex-map-fields";
import { ToastProgrammatic as Toast } from "buefy";
import Panel from "@/atoms/Panel.vue";

export default {
  name: "Login",
  components: { Panel },
  mounted() {
    const currentRoute = this.$router.currentRoute;
    const email = currentRoute.query.email;
    if (email) {
      this.$router.push({
        name: currentRoute.name,
        query: omit(currentRoute.query, "email")
      });
      this.loginEmail = email;
    }

    this.focusInput();
    this.$ga.page(this.$router);
  },
  computed: {
    isValid() {
      return this.$refs.email.isValid;
    },
    ...mapFields("authentication", ["loginEmail"])
  },
  methods: {
    focusInput() {
      this.$nextTick(this.$refs.email.focus());
    },
    checkHtml5Validity() {
      this.$refs.email.checkHtml5Validity();
    },
    login() {
      this.checkHtml5Validity();
      if (!this.isValid) {
        Toast.open({
          duration: 3000,
          message: "Oops. Looks like some mandatory information is missing.",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }

      this.$store.dispatch("authentication/login");
    }
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height;
  margin-bottom: 12rem;
}
#image {
  height: 10rem;
}
#login-button-container {
  padding-top: 1rem;
}
</style>
