export const cities = {
	"ChIJAVkDPzdOqEcRcDteW0YgIQQ": {
		name: "Berlin",
		placeId: "ChIJAVkDPzdOqEcRcDteW0YgIQQ",
		description: "Berlin, Germany",
	},
	"ChIJdd4hrwug2EcRmSrV3Vo6llI": {
		name: "London",
		placeId: "ChIJdd4hrwug2EcRmSrV3Vo6llI",
		description: "London, UK",
	},
	"ChIJwYCC5iqLOxARy9nDZ6OHntw": {
		name: "Lagos",
		placeId: "ChIJwYCC5iqLOxARy9nDZ6OHntw",
		description: "Lagos, Nigeria",
	},
}
