<template>
  <section class="section">
    <div class="container">
      <Panel>
        <div class="columns is-mobile is-vcentered" id="panel">
          <div class="column has-text-centered">
            <h1 class="is-size-4">{{ message }}</h1>
          </div>
        </div>
      </Panel>
    </div>
  </section>
</template>

<script>
import Panel from "@/atoms/Panel.vue";

export default {
  name: "MessageBox",
  props: {
    message: String
  },
  components: { Panel }
};
</script>

<style lang="scss" scoped>
#panel {
  min-height: 200px;
}
</style>
