<template>
  <div class="has-text-centered">
    <b-steps
      v-model="activeStep"
      size="is-small"
      :has-navigation="false"
      animated
    >
      <b-step-item>
        <div
          class="is-size-4 is-size-5-mobile has-text-weight-semibold margin-xxl"
        >
          Receive a weekly email
          <span class="is-inline-block">introduction 👇</span>
        </div>
        <div class="margin-xxl has-text-centered">
          <div class="is-inline-block">
            <AccountMatchingFormLookingFor
              :computeUrlNextSteps="computeUrlNextSteps"
            />
          </div>
        </div>
        <b-button
          type="is-primary"
          size="is-medium"
          @click="nextStep('next_button_looking_for')"
        >
          Next
        </b-button>
      </b-step-item>

      <b-step-item>
        <div class="is-size-4 is-size-5-mobile  margin-xl">
          <div>Thank you for joining!</div>
          <div class="has-text-weight-semibold">
            Welcome to <span class="is-inline-block">Frenl 😊</span>
          </div>
        </div>
        <div class="is-inline-block" style="width: 18rem">
          <SignupForm :trackingLabel="trackingLabel" />
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import AccountMatchingFormLookingFor from "@/molecules/AccountMatchingFormLookingFor.vue";
import SignupForm from "@/molecules/SignupForm.vue";

export default {
  name: "SignupFunnel",
  components: {
    AccountMatchingFormLookingFor,
    SignupForm
  },
  props: {
    trackingLabel: String,
    computeUrlNextSteps: Boolean
  },
  computed: {},
  methods: {
    async nextStep(label) {
      this.$ga.event(label, "click", this.trackingLabel);
      this.activeStep++;
    }
  },
  data() {
    return {
      activeStep: 0
    };
  }
};
</script>

<style lang="scss" scoped>
#image {
  height: 10rem;
}
#signup-button-container {
  padding-top: 1rem;
}
</style>
