import axios from 'axios';

export default class extends axios.Axios {
	constructor(serverUrl) {
		super({
			baseURL: serverUrl,
			withCredentials: true,
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json; charset=utf-8"
			}
		});
	}
}
