import { getField, updateField } from "@/lib/vuex-map-fields";
import hash from "object-hash";

function initialState() {
    return {
        routes: [],
    }
}

export default class {
    namespaced = true

    state = initialState()

    getters = {
        getField,
        routesByHash(state) {
            const index = {}
            for (const route of state.routes) {
                index[hash(
                    route.payload
                )] = route
            }
            return index
        }
    }

    mutations = {
        updateField,
        set(state, payload) {
            state.routes = payload.routes
        },
        reset(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
    }

    actions = {
        reset({
            commit
        }) {
            commit("reset")
        },
    }
}
