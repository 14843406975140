import { getField, updateField } from "@/lib/vuex-map-fields";

import Account from "@/resources/account-store"
import AccountProfile from "@/resources/account-profile-store"

function initialState() {
  return {
    savedAccountHash: "",
    savedAccountProfileHash: "",

    getUserAccount: {
      inFlight: false,
      error: null
    },
    updateUserAccount: {
      inFlight: false,
      error: null
    },
  }
}

export default class {
  namespaced = true

  modules = {
    account: new Account(),
    accountProfile: new AccountProfile(),
  }

  state = initialState()

  getters = {
    getField,

    accountSaved: (state, getters) => getters["account/hash"] === state.savedAccountHash,
    accountProfileSaved: (state, getters) => getters["accountProfile/hash"] === state.savedAccountProfileHash,

    inFlight: state => state.getUserAccount.inFlight || state.updateUserAccount.inFlight,
    errors: state => [
      state.getUserAccount.error,
      state.updateUserAccount.error,
    ].filter((val) => val ? true : false),
  }

  mutations = {
    updateField,

    getUserAccountRequest(state) {
      state.getUserAccount.inFlight = true
    },
    getUserAccountSuccess(state) {
      state.getUserAccount.inFlight = false
    },
    getUserAccountFailure(state, payload) {
      state.getUserAccount.inFlight = false
      state.getUserAccount.error = payload.error
    },

    updateUserAccountRequest(state) {
      state.updateUserAccount.inFlight = true
    },
    updateUserAccountSuccess(state) {
      state.updateUserAccount.inFlight = false
    },
    updateUserAccountFailure(state, payload) {
      state.updateUserAccount.inFlight = false
      state.updateUserAccount.error = payload.error
    },

    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.getUserAccount.error = null
      state.updateUserAccount.error = null
    },
  }

  actions = {
    async getUserAccount({
      commit,
      state,
      rootState,
      getters
    }) {
      if (state.getUserAccount.inFlight) {
        return;
      }
      try {
        commit("getUserAccountRequest")
        const res = await this.userMatcherCli.getUserAccount(rootState.authentication.token)
        commit("getUserAccountSuccess")

        commit({
          type: "account/set",
          account: res.account,
        })
        commit({
          type: "accountProfile/set",
          accountProfile: res.account.accountProfile,
        })
        commit({
          type: "updateField",
          path: "savedAccountHash",
          value: getters["account/hash"]
        });
        commit({
          type: "updateField",
          path: "savedAccountProfileHash",
          value: getters["accountProfile/hash"]
        });
      } catch (err) {
        commit({
          type: "getUserAccountFailure",
          error: err
        })
      }
    },

    async updateUserAccount({
      commit,
      state,
      rootState,
      getters
    }) {
      if (state.updateUserAccount.inFlight) {
        return;
      }
      try {
        commit("updateUserAccountRequest")
        await this.userMatcherCli.updateUserAccount(
          rootState.authentication.token,
          state.account,
          state.accountProfile,
        )
        commit("updateUserAccountSuccess")

        commit({
          type: "updateField",
          path: "savedAccountHash",
          value: getters["account/hash"]
        });
        commit({
          type: "updateField",
          path: "savedAccountProfileHash",
          value: getters["accountProfile/hash"]
        });
      } catch (err) {
        commit({
          type: "updateUserAccountFailure",
          error: err
        })
      }
    },

    reset({
      commit,
      dispatch
    }) {
      commit("reset")
      dispatch("account/reset")
      dispatch("accountProfile/reset")
    },
  }
}
