<template functional>
  <div
    class="panel has-text-left"
    :style="
      `min-width:${props.minWidth}; max-width:${props.maxWidth}; min-height:${props.minHeight};`
    "
    :class="{
      clickable: props.clickable,
      'padding-xs': props.paddingXs,
      'padding-sm': props.paddingSm,
      'grey-background': props.greyBackground
    }"
    v-on="listeners"
  >
    <slot name="header"></slot>
    <div class="padding">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: {
    minWidth: String,
    maxWidth: String,
    minHeight: String,
    clickable: Boolean,
    paddingXs: Boolean,
    paddingSm: Boolean,
    greyBackground: Boolean
  }
};
</script>

<style scoped lang="scss">
.panel {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: $black-color;
  margin-left: auto;
  margin-right: auto;
}
.padding {
  padding: 1.3rem 1rem;
}
@media only screen and (min-width: $tablet) {
  .padding {
    padding: 1.8rem 2rem;
  }
  .padding.padding-xs {
    padding: 0.8rem 1rem;
  }
  .padding.padding-sm {
    padding: 1.6rem 1.6rem;
  }
}

.clickable {
  cursor: pointer;
  transition: margin-top 0.2s, margin-bottom 0.2s;
}
.clickable:hover {
  background-color: rgb(248, 248, 248);
  margin-top: 0.6rem;
  margin-bottom: -0.6rem;
}

.grey-background {
  background-color: $background-color;
  background-image: $background-image;
}
</style>
