import { focuses } from "@/enums/focuses";
import { communities } from "@/enums/communities";
import { getField, updateField } from "@/lib/vuex-map-fields";
import hash from "object-hash";

function initialState() {
    return {
        currentTitle: "",
        currentCompany: "",
        currentPlace: {
            id: "",
        },
        personas: [],
        focuses: [],
        personaAspirations: [],
        focusAspirations: [],
        communities: [],
        interview: [],
        interviewHtml: "",
    }
}

export default class {
    namespaced = true

    state = initialState()

    getters = {
        getField(state) {
            return function (path) {
                const value = getField(state)(path)
                switch (path) {
                    case "interview": {
                        const firstEmptyIndex = value.findIndex((interview) => !interview.answerMd)
                        if (firstEmptyIndex === -1) {
                            return value
                        }
                        return value.slice(0, firstEmptyIndex + 1)
                    }
                    default: {
                        return value
                    }
                }
            }
        },
        completenessCount: (state) => {
            let completedCounter = 0;
            for (const value of Object.values(state)) {
                if (value.length > 0) {
                    completedCounter++
                }
            }
            return completedCounter
        },
        completenessTotalCount: (state) => {
            return Object.keys(state).length
        },
        hash(state) {
            return hash(state)
        }
    }

    mutations = {
        updateField(state, payload) {
            switch (payload.path) {
                case "personas": {
                    state.personas = payload.value.sort()
                    state.focuses = state.focuses.filter(focus => state.personas.includes(focuses[focus].persona))
                    state.communities = state.communities.filter(community => state.personas.includes(communities[community].persona))
                    break;
                }
                case "focuses": {
                    state.focuses = payload.value.sort()
                    break;
                }
                case "personaAspirations": {
                    state.personaAspirations = payload.value.sort()
                    state.focusAspirations = state.focusAspirations.filter(focus => state.personaAspirations.includes(focuses[focus].persona))
                    break;
                }
                case "focusAspirations": {
                    state.focusAspirations = payload.value.sort()
                    break;
                }
                case "communities": {
                    state.communities = payload.value.sort()
                    break;
                }
                default: {
                    updateField(state, payload)
                }
            }
        },
        set(state, payload) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = payload.accountProfile[key] ? payload.accountProfile[key] : s[key];
            })
        },
        reset(state) {
            const s = initialState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },
    }

    actions = {
        reset({
            commit
        }) {
            commit("reset")
        },
    }
}
