import { getField, updateField } from "@/lib/vuex-map-fields";
import hash from "object-hash";
import omit from "lodash/omit"

function initialState() {
    return {
        id: 0,
        title: "",
        titleSlug: "",
        illustrationUrl: "",
        quote: "",
        headlineMd: "",
        headlineHtml: "",
        projectName: "",
        projectUrl: "",
        actionName: "",
        actionUrl: "",
        ready: false,
        interviewMd: "",
        interviewHtml: "",
        matchingRound: {}
    }
}

export default class {
    namespaced = true

    state = initialState()

    getters = {
        getField,
        hash(state) {
            return hash(omit(state, ["interviewHtml"]))
        }
    }

    mutations = {
        updateField,
        set(state, payload) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = payload.staticInterview[key] ? payload.staticInterview[key] : s[key];
            })
        },
        reset(state) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = s[key]
            })
        },
    }

    actions = {
        reset({
            commit
        }) {
            commit("reset")
        },
    }
}
