import { getField, updateField } from "@/lib/vuex-map-fields";
import AutocompletePredictions from "@/resources/autocomplete-predictions-store"

function initialState() {
  return {
    location: "",

    autocompleteLocation: {
      inFlight: false,
      error: null
    },
  }
}

export default class {
  namespaced = true

  modules = {
    autocompletePredictions: new AutocompletePredictions(),
  }

  state = initialState()

  getters = {
    getField,

    inFlight: state => state.autocompleteLocation.inFlight,
    errors: (state) => [
      state.autocompleteLocation.error,
    ].filter((val) => val ? true : false)
  }

  mutations = {
    updateField,

    autocompleteLocationRequest(state) {
      state.autocompleteLocation.inFlight = true
    },
    autocompleteLocationSuccess(state) {
      state.autocompleteLocation.inFlight = false
    },
    autocompleteLocationFailure(state, payload) {
      state.autocompleteLocation.inFlight = false
      state.autocompleteLocation.error = payload.error
    },

    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.autocompleteLocation.error = null
    },
  }

  actions = {
    async autocompleteLocation({
      commit,
      state
    }) {
      if (state.autocompleteLocation.inFlight) {
        return;
      }
      try {
        commit("autocompleteLocationRequest")
        const res = await this.userMatcherCli.autocompleteLocation(state.location)
        commit("autocompleteLocationSuccess")
        commit({
          type: "autocompletePredictions/set",
          autocompletePredictions: res.autocompletePredictions,
        })
      } catch (err) {
        commit({
          type: "autocompleteLocationFailure",
          error: err
        })
      }
    },

    reset({
      commit,
      dispatch
    }) {
      commit("reset")
      dispatch("autocompletePredictions/reset")
    },
  }
}
