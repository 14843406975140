import Vue from "vue"
import VueRouter from "vue-router"
import omit from "lodash/omit";

import { cities } from "@/enums/cities";

import MeetupLandingPage from "@/pages/MeetupLandingPage.vue";
import LandingPage from "@/pages/LandingPage.vue";
import StaticInterviews from "@/pages/StaticInterviews.vue";
import StaticInterviewLandingPage from "@/pages/StaticInterviewLandingPage.vue";
import Login from "@/pages/Login.vue";
import Signup from "@/pages/Signup.vue";
import VerifyAccount from "@/pages/VerifyAccount.vue";
import VerificationEmail from "@/pages/VerificationEmail.vue";
import LoginEmail from "@/pages/LoginEmail.vue";
import UserAccount from "@/pages/UserAccount.vue";
import Onboarding from "@/pages/Onboarding.vue";
import UserStaticInterview from "@/pages/UserStaticInterview.vue";

Vue.use(VueRouter)

const routes = [
  {
    name: "interviews",
    path: "/interviews",
    component: StaticInterviews,
  },
  {
    name: "userStaticInterview",
    path: "/interviews/new",
    component: UserStaticInterview,
    meta: {
      redirectIfLoggedOut: true
    }
  },
  {
    name: "interviewLandingPage",
    path: "/interview/:titleSlug",
    component: StaticInterviewLandingPage,
  },
  {
    name: "userAccount",
    path: "/account",
    component: {
      functional: true,
      render(h, ctx) {
        if (!ctx.parent.$store.state.accountManagement.account.email) {
          return h()
        }
        return ctx.parent.$store.state.accountManagement.account.onboarded ?
          h(UserAccount) :
          h(Onboarding)
      }
    },
    meta: {
      redirectIfLoggedOut: true
    }
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    name: "signup",
    path: "/signup",
    component: Signup,
    meta: {
      redirectIfLoggedIn: true
    }
  },
  {
    name: "verifyAccount",
    path: "/verify-account",
    component: VerifyAccount,
  },
  {
    name: "verificationEmail",
    path: "/verification-email",
    component: VerificationEmail,
  },
  {
    name: "loginEmail",
    path: "/login-email",
    component: LoginEmail,
  },
  {
    name: "meetupLandingPage",
    path: "/meetups",
    component: MeetupLandingPage,
  },
  {
    name: "landingPage",
    path: "",
    component: LandingPage,
  },
  {
    name: "cityLandingPage",
    path: "/:citySlug",
    component: LandingPage,
  },
  {
    name: "focusedLandingPage",
    path: "/:citySlug/:slug",
    component: LandingPage,
  },
]

export default class extends VueRouter {
  constructor(store) {
    super({
      mode: "history",
      routes: routes,
    })
    this.store = store

    this.beforeEach(async (to, from, next) => {
      this.store.commit({
        type: "routing/updateField",
        path: "path",
        value: to.path
      });
      await this.store.dispatch({
        type: "routing/getCurrentRoute",
      })
      const route = this.store.getters["routing/currentRoute/getField"]
      const routeTitle = route("title")
      const routeDescription = route("description")
      const routeRobots = route("robots")
      const routeCanonical = route("canonical")
      let routePayload = route("payload")
      const routeBreadCrumbs = route("breadCrumbs")

      const metaDescription = document.querySelector('head meta[name="description"]');
      const metaRobots = document.querySelector('head meta[name="robots"]');
      const linkCanonical = document.querySelector('head link[rel="canonical"]');
      const metaOGTitle = document.querySelector('head meta[property="og:title"]');
      const metaOGDescription = document.querySelector('head meta[property="og:description"]');
      const metaOGURL = document.querySelector('head meta[property="og:url"]');
      const title = document.querySelector("head title");
      const breadCrumbs = document.querySelector('head script[type="application/ld+json"]');

      metaDescription.setAttribute("content", routeDescription)
      metaRobots.setAttribute("content", routeRobots)
      linkCanonical.setAttribute("href", routeCanonical)
      metaOGTitle.setAttribute("content", routeTitle)
      metaOGDescription.setAttribute("content", routeDescription)
      metaOGURL.setAttribute("content", routeCanonical)
      title.textContent = routeTitle
      breadCrumbs.textContent = routeBreadCrumbs ? JSON.stringify(routeBreadCrumbs) : ""

      if (process.env.NODE_ENV !== 'development' && (to.name === "landingPage" || routePayload)) {
        routePayload = routePayload ? routePayload : {}
        const city = cities[routePayload.placeId];
        this.store.commit({
          type: "accountManagement/accountProfile/updateField",
          path: "currentPlace.id",
          value: city ? city.placeId : ""
        });
        this.store.commit({
          type: "accountManagement/accountProfile/updateField",
          path: "currentPlace.description",
          value: city ? city.description : ""
        });
        this.store.commit({
          type: "accountManagement/accountProfile/updateField",
          path: "personaAspirations",
          value: routePayload.persona ? [routePayload.persona] : []
        });
        this.store.commit({
          type: "accountManagement/accountProfile/updateField",
          path: "focusAspirations",
          value: routePayload.focus ? [routePayload.focus] : []
        });
      }

      // We don't scroll up when moving between the landing pages.
      if (!(["landingPage", "cityLandingPage", "focusedLandingPage"].includes(from.name) && ["landingPage", "cityLandingPage", "focusedLandingPage"].includes(to.name))) {
        window.scrollTo(0, 0);
      }

      if (to.query.token) {
        this.store.commit({
          type: "authentication/updateField",
          path: "token",
          value: to.query.token
        });
        next({
          name: to.name,
          query: omit(to.query, "token")
        });
        return;
      }

      if (this.store.getters["loggedIn"]) {
        if (to.meta.redirectIfLoggedIn) {
          next({ name: "userAccount", query: to.query })
        } else {
          this.store.dispatch({
            type: "accountManagement/getUserAccount",
          })
          next()
        }
      } else {
        to.meta.redirectIfLoggedOut ? next({ name: "login", query: to.query }) : next()
      }
    })
  }
}
