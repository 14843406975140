import { render, staticRenderFns } from "./CompletenessStars.vue?vue&type=template&id=19c9fa77&scoped=true&"
import script from "./CompletenessStars.vue?vue&type=script&lang=js&"
export * from "./CompletenessStars.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c9fa77",
  null
  
)

export default component.exports