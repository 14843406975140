<template>
  <b-navbar
    id="navbar"
    wrapper-class="container"
    fixed-top
    :is-active.sync="menuIsActive"
  >
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ name: 'landingPage' }">
        <img src="/static/logo.png" id="logo" draggable="false" />
      </b-navbar-item>
      <b-navbar-item tag="div">
        <div class="loader" :class="{ hidden: !inFlight }" id="loader" />
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item
        class="has-text-weight-semibold"
        :class="{ highlighted: currentRoute === 'landingPage' }"
        tag="router-link"
        :to="{ name: 'landingPage' }"
      >
        Home
      </b-navbar-item>
      <b-navbar-item
        class="has-text-weight-semibold"
        :class="{ highlighted: currentRoute === 'interviews' }"
        tag="router-link"
        :to="{ name: 'interviews' }"
        v-if="currentRoute"
      >
        Interview highlights
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div" v-if="!loggedIn">
        <router-link
          :to="{ name: 'login' }"
          id="login-button"
          @click.native="toggleMenu"
        >
          <b-button>
            Log in
          </b-button>
        </router-link>

        <router-link :to="{ name: 'signup' }" @click.native="trackSignupClick">
          <b-button class="is-primary">
            Sign up
          </b-button>
        </router-link>
      </b-navbar-item>

      <b-navbar-dropdown
        class="is-hidden-touch"
        :label="firstName"
        v-if="loggedIn"
      >
        <b-navbar-item
          tag="router-link"
          :class="{ highlighted: currentRoute === 'userAccount' }"
          :to="{ name: 'userAccount' }"
          @click.native="toggleMenu"
        >
          My account
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item @click.native="logout">
          Log out
        </b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-item
        class="is-hidden-desktop has-text-weight-semibold"
        tag="div"
        v-if="loggedIn"
      >
        <b-navbar-item
          tag="router-link"
          :class="{ highlighted: currentRoute === 'userAccount' }"
          :to="{ name: 'userAccount' }"
          @click.native="toggleMenu"
        >
          My account
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item @click.native="logout">
          Log out
        </b-navbar-item>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    firstName() {
      return this.$store.state.accountManagement.account.firstName;
    },
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    inFlight() {
      return this.$store.getters["inFlight"];
    },
    currentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    trackSignupClick() {
      this.$ga.event("signup_link", "click", "navbar");
      this.toggleMenu();
    },
    toggleMenu() {
      this.menuIsActive = !this.menuIsActive;
    },
    logout() {
      this.$store.dispatch("authentication/logout");
      this.toggleMenu();
    }
  },
  data: () => ({
    menuIsActive: false
  })
};
</script>

<style lang="scss" scoped>
a {
  color: $black-color;
}
a:hover {
  color: $black-color;
}

#navbar {
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25),
    inset 0 0 32px 0 rgba(0, 0, 0, 0.025);
  border-bottom: 1px solid #d3d3d3;
  font-size: 1.1rem;
}

#logo {
  margin-top: -0.25rem;
  max-height: 2.3rem;
}
#loader {
  height: 2em;
  width: 2em;
  margin-bottom: -0.3rem;
  margin-left: -0.4rem;
  font-size: 1rem;
}
.hidden {
  visibility: hidden;
}

.highlighted {
  background-color: #fafafa !important;
  color: #ff6c28 !important;
}

#login-button {
  margin-right: 0.4rem;
}
</style>
