<template>
  <LocationPicker
    label="Near your location"
    placeholder="City"
    icon="home"
    :inFlight="inFlight"
    :autocompletePredictions="autocompletePredictions"
    @typing="getPredictions"
    @select="select"
    v-model="currentPlaceDesc"
  />
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import LocationPicker from "@/atoms/LocationPicker.vue";

export default {
  name: "CityPicker",
  components: { LocationPicker },
  props: {},
  computed: {
    ...mapFields("localization", ["location", "autocompleteLocation.inFlight"]),
    ...mapFields("localization/autocompletePredictions", [
      "autocompletePredictions"
    ]),
    ...mapFields("accountManagement/accountProfile", {
      currentPlaceId: "currentPlace.id",
      currentPlaceDesc: "currentPlace.description"
    })
  },
  methods: {
    getPredictions: async function(location) {
      this.location = location;
      await this.$store.dispatch({ type: "localization/autocompleteLocation" });
    },
    select(placeId) {
      this.currentPlaceId = placeId;
    }
  }
};
</script>

<style scoped>
</style>
