<template>
  <div>
    <div
      class="has-text-grey has-text-left"
      v-if="matchingRoundNumber && matchingRoundCreatedDate"
    >
      Round {{ matchingRoundNumber }} - {{ matchingRoundCreatedDate }}
    </div>

    <div
      class="is-size-4 has-text-weight-semibold text-compact"
      v-if="!emphasize"
    >
      Showing
      <a :href="projectUrl" target="_blank"> {{ projectName }}</a>
    </div>
    <h1
      class="is-size-3 is-size-4-mobile has-text-weight-semibold text-compact"
      v-if="emphasize"
    >
      Showing
      <a :href="projectUrl" target="_blank"> {{ projectName }}</a>
    </h1>
    <div class="is-size-4 is-size-5-mobile text-compact margin-lg">
      {{ title }}
    </div>

    <img
      class="image margin-xxl"
      :src="illustrationUrl"
      v-if="illustrationUrl"
    />
    <div
      class="margin-xl"
      style="border-top:1px solid #e3e3e3; width:100%; margin-left: auto; margin-right: auto;"
    />

    <div class="html-content-headline is-italic margin-xl">
      <div v-html="headlineHtml" />
    </div>

    <div v-if="!summary">
      <div
        class="margin-xxl"
        style="border-top:1px solid #e3e3e3; width:100%; margin-left: auto; margin-right: auto;"
      />
      <div class="html-content margin-xxl" v-html="interviewHtml" />
      <div class="margin-sm has-text-weight-semibold">
        <a :href="projectUrl" target="_blank">
          🌍 Go to the {{ projectName }} website
        </a>
      </div>
      <div class="has-text-weight-semibold">
        <a :href="actionUrl" target="_blank">🌟 {{ actionName }}</a>
      </div>
    </div>

    <div class="has-text-centered">
      <router-link
        class="button is-primary"
        :to="{
          name: 'interviewLandingPage',
          params: { titleSlug: titleSlug }
        }"
        v-if="summary"
      >
        📖 Read the full interview
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "StaticInterview",
  components: {},
  props: {
    title: String,
    titleSlug: String,
    headlineHtml: String,
    illustrationUrl: String,
    projectName: String,
    projectUrl: String,
    actionName: String,
    actionUrl: String,
    interviewHtml: String,

    matchingRoundNumber: Number,
    matchingRoundCreatedDate: String,

    emphasize: Boolean,
    summary: Boolean
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.image {
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}
.html-content-headline /deep/ p {
  margin: 0.6rem 0;
  line-height: 1.5;
}
.html-content /deep/ p {
  margin: 1rem 0;
  line-height: 1.5;
}
.html-content /deep/ h1 {
  font-weight: 600;
  font-size: 21px;
  letter-spacing: -0.02em;
}
.html-content /deep/ h1:not(:first-child) {
  margin-top: 40px;
}
.html-content /deep/ ul {
  list-style: disc;
}
.html-content /deep/ li {
  margin: 8px 0 8px 19px;
}
</style>
