<template>
  <div id="container">
    <div class="section">
      <div class="container">
        <b-steps
          v-model="activeStep"
          size="is-medium"
          :has-navigation="false"
          animated
        >
          <b-step-item label="Your profile" icon="user">
            <div
              class="has-text-centered is-size-4 is-size-5-mobile margin-xxl"
            >
              That's the last step I promise 😬
            </div>

            <div class="columns is-desktop is-variable is-3-desktop margin-xxl">
              <div class="column">
                <Panel>
                  <AccountMatchingFormYourself />
                </Panel>
              </div>
              <div class="column">
                <Panel>
                  <AccountSocialForm />
                </Panel>
              </div>
            </div>

            <b-button type="is-primary" size="is-medium" @click="save">
              Activate my account
            </b-button>
          </b-step-item>
        </b-steps>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import Panel from "@/atoms/Panel.vue";
import AccountSocialForm from "@/molecules/AccountSocialForm.vue";
import AccountMatchingFormYourself from "@/molecules/AccountMatchingFormYourself.vue";

export default {
  name: "Onboarding",
  components: {
    Panel,
    AccountSocialForm,
    AccountMatchingFormYourself
  },
  mounted() {
    this.$ga.page(this.$router);
  },
  methods: {
    async save() {
      this.onboarded = true;
      this.active = true;

      await this.$store.dispatch({
        type: "accountManagement/updateUserAccount"
      });

      this.$ga.event("activate_button", "click", "onboarding");

      this.$router.push({ name: "userAccount" });
    }
  },
  computed: {
    ...mapFields("accountManagement/account", ["onboarded", "active"])
  },
  data() {
    return {
      activeStep: 0
    };
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height - 0.5rem;
  margin-bottom: 12rem;
}
</style>
