<template>
  <b-rate
    class="is-inline-block"
    icon-pack="fas"
    icon="star"
    :max="5"
    size="is-medium"
    :texts="scoreTexts"
    :show-text="true"
    disabled
    v-model="score"
  />
</template>

<script>
export default {
  name: "CompletenessStars",
  props: {},
  computed: {
    score() {
      const accountCount = this.$store.getters[
        "accountManagement/account/completenessCount"
      ];
      const profileCount = this.$store.getters[
        "accountManagement/accountProfile/completenessCount"
      ];

      const accountTotalCount = this.$store.getters[
        "accountManagement/account/completenessTotalCount"
      ];
      const profileTotalCount = this.$store.getters[
        "accountManagement/accountProfile/completenessTotalCount"
      ];

      const completedRate =
        (accountCount + profileCount) / (accountTotalCount + profileTotalCount);

      const completedStars = Math.min(Math.round(1 + completedRate * 4), 5);

      // const completedAccountRate = accountCount / accountTotalCount ;
      // const completedProfileRate = profileCount / profileTotalCount;

      // const completedAccountStars = Math.min(
      //   Math.round(completedAccountRate * 2),
      //   2
      // );
      // const completedProfileStars = Math.min(
      //   Math.round(completedProfileRate * 2),
      //   2
      // );

      return completedStars;
    }
  },
  data() {
    return {
      scoreTexts: [
        "Poor profile",
        "Poor profile",
        "Average profile",
        "Good profile",
        "Outstanding profile 🎉"
      ]
    };
  }
};
</script>

<style scoped>
</style>
