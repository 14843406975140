import { getField, updateField } from "@/lib/vuex-map-fields";
import Route from "@/resources/route-store"

function initialState() {
  return {
    path: "",

    getCurrentRoute: {
      inFlight: false,
      error: null
    },
  }
}

export default class {
  namespaced = true

  modules = {
    currentRoute: new Route(),
  }

  state = initialState()

  getters = {
    getField,

    inFlight: state => state.getCurrentRoute.inFlight,
    errors: (state) => [
      state.getCurrentRoute.error,
    ].filter((val) => val ? true : false)
  }

  mutations = {
    updateField,

    getCurrentRouteRequest(state) {
      state.getCurrentRoute.inFlight = true
    },
    getCurrentRouteSuccess(state) {
      state.getCurrentRoute.inFlight = false
    },
    getCurrentRouteFailure(state, payload) {
      state.getCurrentRoute.inFlight = false
      state.getCurrentRoute.error = payload.error
    },

    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.getCurrentRoute.error = null
    },
  }

  actions = {
    async getCurrentRoute({
      commit,
      state,
    }) {
      if (state.getCurrentRoute.inFlight) {
        return;
      }
      try {
        commit("getCurrentRouteRequest")
        const res = await this.webserverCli.getRoutes({ path: state.path })
        commit("getCurrentRouteSuccess")
        if (res && res.length > 0) {
          commit({
            type: "currentRoute/set",
            route: res[0],
          })
        }
      } catch (err) {
        commit({
          type: "getCurrentRouteFailure",
          error: err
        })
      }
    },

    reset({
      commit,
      dispatch
    }) {
      commit("reset")
      dispatch("currentRoute/reset")
    },
  }
}
