<template>
  <div id="container" v-if="staticInterviews.length !== 0">
    <section class="section">
      <div class="container">
        <h1
          class="is-size-3 is-size-4-mobile has-text-weight-semibold text-compact margin-xl"
        >
          🔦 Interview highlights
        </h1>
        <div
          class="margin-xl"
          :key="i.id"
          v-for="(i, index) in staticInterviews"
        >
          <Panel>
            <div
              class="columns is-desktop is-vcentered is-variable is-4 is-flex-touch"
            >
              <div class="column is-3-desktop has-text-centered">
                <AccountProfile
                  class="margin-lg"
                  :firstName="i.account.firstName"
                  :lastName="i.account.lastName"
                  :email="i.account.email"
                  :contact="i.account.contact"
                  :pictureUrl="i.account.pictureUrl"
                  :currentTitle="i.account.accountProfile.currentTitle"
                  :currentCompany="i.account.accountProfile.currentCompany"
                  :currentPlaceDesc="
                    i.account.accountProfile.currentPlace.description
                  "
                  showBy
                />
              </div>
              <div class="column round-column">
                <StaticInterview
                  :title="i.title"
                  :titleSlug="i.titleSlug"
                  :headlineHtml="i.headlineHtml"
                  :illustrationUrl="i.illustrationUrl"
                  :projectName="i.projectName"
                  :projectUrl="i.projectUrl"
                  :matchingRoundNumber="i.matchingRound.number"
                  :matchingRoundCreatedDate="i.matchingRound.createdDate"
                  summary
                />
              </div>
            </div>
          </Panel>

          <div class="cta-section" v-if="index === 0">
            <div class="has-text-grey">
              Got something to show or ask?
            </div>
            <div>
              <router-link :to="{ name: 'signup' }" v-if="!loggedIn">
                Get displayed in the interviews
              </router-link>
              <a :href="contactHref" v-if="loggedIn">
                Get displayed in the interviews
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMultiRowFields } from "@/lib/vuex-map-fields";
import AccountProfile from "@/organisms/AccountProfile.vue";
import StaticInterview from "@/organisms/StaticInterview.vue";
import Panel from "@/atoms/Panel.vue";

export default {
  name: "StaticInterviews",
  components: {
    Panel,
    AccountProfile,
    StaticInterview
  },
  mounted() {
    this.$store.dispatch({
      type: "staticInterviewManagement/listStaticInterviews"
    });

    this.$ga.page(this.$router);
  },
  computed: {
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    contactHref() {
      return "mailto:" + this.$config.contactEmail;
    },
    ...mapMultiRowFields("staticInterviewManagement/staticInterviews", [
      "staticInterviews"
    ])
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height - 0.2rem;
  margin-bottom: 12rem;
}
@media only screen and (max-width: $desktop) {
  .columns {
    flex-direction: column-reverse;
  }
  .column {
    width: 100%;
  }
  .round-column {
    margin-bottom: 1rem;
  }
}
.cta-section {
  margin-top: 2rem;
}
</style>
