import { getField, updateField } from "@/lib/vuex-map-fields";
import Accounts from "@/resources/accounts-store"
import Routes from "@/resources/routes-store"
import { personas } from "@/enums/personas";
import { focusesByPersona } from "@/enums/focuses";
import { cities } from "@/enums/cities";
import hash from "object-hash";
import uniqBy from "lodash/uniqBy";

function initialState() {
  return {
    listTopAccounts: {
      inFlight: false,
      error: null
    },
    getNextRoutes: {
      inFlight: false,
      error: null
    },
  }
}

export default class {
  namespaced = true

  modules = {
    topAccounts: new Accounts(),
    nextRoutes: new Routes(),
  }

  state = initialState()

  getters = {
    getField,

    urlNextSteps(state, getters) {
      const nextSteps = [];

      if (Object.keys(getters.currentStep).length > 0) {
        nextSteps.push(getters.currentStep);
      }
      for (const persona of getters.personaAspirationList) {
        if (Object.keys(persona.nextStep).length > 0) {
          nextSteps.push(persona.nextStep);
        }
      }
      for (const focus of getters.focusAspirationList) {
        if (Object.keys(focus.nextStep).length > 0) {
          nextSteps.push(focus.nextStep);
        }
      }
      for (const city of getters.cityList) {
        if (Object.keys(city.nextStep).length > 0) {
          nextSteps.push(city.nextStep);
        }
      }

      return uniqBy(nextSteps, hash);
    },

    decoratedPersonaAspirationList(state, getters) {
      const personaList = [];
      for (const persona of getters.personaAspirationList) {
        const route = getters["nextRoutes/routesByHash"][hash(persona.nextStep)];
        const newPersona = { ...persona };
        newPersona.path = route ? route.path : null;
        personaList.push(newPersona);
      }
      return personaList;
    },
    decoratedFocusAspirationList(state, getters) {
      const focusList = [];
      for (const focus of getters.focusAspirationList) {
        const route = getters["nextRoutes/routesByHash"][hash(focus.nextStep)];
        const newFocus = { ...focus };
        newFocus.path = route ? route.path : null;
        focusList.push(newFocus);
      }
      return focusList;
    },
    decoratedCityList(state, getters) {
      const cityList = [];
      for (const city of getters.cityList) {
        const route = getters["nextRoutes/routesByHash"][hash(city.nextStep)];
        const newCity = { ...city };
        newCity.path = route ? route.path : null;
        cityList.push(newCity);
      }
      return cityList;
    },

    personaAspirationList(state, getters, rootState) {
      const personaList = [];
      for (const persona of Object.values(personas)) {
        const newPersona = { ...persona };
        newPersona.name = persona.namePlural;

        newPersona.nextStep = computeNextStep(
          rootState.accountManagement.accountProfile,
          persona,
          getters.indexedPersonaAspirations,
          "personaAspirations"
        );

        personaList.push(newPersona);
      }
      return personaList;
    },
    focusAspirationList(state, getters, rootState) {
      const focusList = [];
      for (const persona of rootState.accountManagement.accountProfile.personaAspirations) {
        for (const focus of focusesByPersona[persona]) {
          const newFocus = { ...focus };

          newFocus.nextStep = computeNextStep(
            rootState.accountManagement.accountProfile,
            focus,
            getters.indexedFocusAspirations,
            "focusAspirations"
          );

          focusList.push(newFocus);
        }
      }
      return focusList;
    },
    cityList(state, getters, rootState) {
      const cityList = [];
      for (const city of Object.values(cities)) {
        const newCity = { ...city };

        newCity.nextStep = computeNextStep(
          rootState.accountManagement.accountProfile,
          city.placeId,
          null,
          "currentPlaceId"
        );

        cityList.push(newCity);
      }
      return cityList;
    },

    indexedPersonaAspirations(state, getters, rootState) {
      const index = {};
      for (const selected of rootState.accountManagement.accountProfile.personaAspirations) {
        index[selected] = true;
      }
      return index;
    },
    indexedFocusAspirations(state, getters, rootState) {
      const index = {};
      for (const selected of rootState.accountManagement.accountProfile.focusAspirations) {
        index[selected] = true;
      }
      return index;
    },

    // Useful to change route when selecting a city.
    currentStep(state, getters, rootState) {
      return computeNextStep(rootState.accountManagement.accountProfile, null, null, null);
    },

    inFlight: state => state.listTopAccounts.inFlight || state.getNextRoutes.inFlight,
    errors: (state) => [
      state.listTopAccounts.error,
      state.getNextRoutes.error,
    ].filter((val) => val ? true : false)
  }

  mutations = {
    updateField,

    listTopAccountsRequest(state) {
      state.listTopAccounts.inFlight = true
    },
    listTopAccountsSuccess(state) {
      state.listTopAccounts.inFlight = false
    },
    listTopAccountsFailure(state, payload) {
      state.listTopAccounts.inFlight = false
      state.listTopAccounts.error = payload.error
    },

    getNextRoutesRequest(state) {
      state.getNextRoutes.inFlight = true
    },
    getNextRoutesSuccess(state) {
      state.getNextRoutes.inFlight = false
    },
    getNextRoutesFailure(state, payload) {
      state.getNextRoutes.inFlight = false
      state.getNextRoutes.error = payload.error
    },

    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.listTopAccounts.error = null
      state.getNextRoutes.error = null
    },
  }

  actions = {
    async listTopAccounts({
      commit,
      state,
      rootState
    }) {
      if (state.listTopAccounts.inFlight) {
        return;
      }
      try {
        commit("listTopAccountsRequest")
        const res = await this.userMatcherCli.listTopAccounts(
          rootState.accountManagement.accountProfile.currentPlace.id,
          rootState.accountManagement.accountProfile.personaAspirations,
          rootState.accountManagement.accountProfile.focusAspirations,
        )
        commit("listTopAccountsSuccess")

        commit({
          type: "topAccounts/set",
          accounts: res.accounts,
        })
      } catch (err) {
        commit({
          type: "listTopAccountsFailure",
          error: err
        })
      }
    },

    async getNextRoutes({
      commit,
      state,
      getters,
    }) {
      if (state.getNextRoutes.inFlight) {
        return;
      }
      try {
        commit("getNextRoutesRequest")
        const res = await this.webserverCli.getRoutes({ payloads: getters.urlNextSteps })
        commit("getNextRoutesSuccess")
        commit({
          type: "nextRoutes/set",
          routes: res,
        })
      } catch (err) {
        commit({
          type: "getNextRoutesFailure",
          error: err
        })
      }
    },

    reset({
      commit,
      dispatch
    }) {
      commit("reset")
      dispatch("topAccounts/reset")
      dispatch("nextRoutes/reset")
    },
  }
}

function computeNextStep(accountProfile, item, isSelected, field) {
  const nextSelection = JSON.parse(
    JSON.stringify({
      personaAspirations: accountProfile.personaAspirations,
      focusAspirations: accountProfile.focusAspirations,
      currentPlaceId: accountProfile.currentPlace.id
    })
  );

  if (item) {
    if (isSelected) {
      if (isSelected[item.id]) {
        nextSelection[field] = nextSelection[field].filter(
          id => id !== item.id
        );
      } else {
        nextSelection[field].push(item.id);
      }
    } else {
      nextSelection[field] = item;
    }
  }

  const nextStep = {};

  switch (nextSelection.personaAspirations.length) {
    case 1:
      nextStep["persona"] = nextSelection.personaAspirations[0];

      switch (nextSelection.focusAspirations.length) {
        case 1:
          nextStep["focus"] = nextSelection.focusAspirations[0];
          break;
        case 0:
          break;
        default:
          return {};
      }
      break;

    case 0:
      break;
    default:
      return {};
  }

  if (nextSelection.currentPlaceId) {
    nextStep["placeId"] = nextSelection.currentPlaceId;
  }

  return nextStep;
}
