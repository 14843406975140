import { getField, updateField } from "@/lib/vuex-map-fields";

function initialState() {
    return {
        name: "",
        title: "",
        description: "",
        robots: "",
        canonical: "",
        payload: null,
        breadCrumbs: null,
    }
}

export default class {
    namespaced = true

    state = initialState()

    getters = {
        getField
    }

    mutations = {
        updateField,
        set(state, payload) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = payload.route[key] ? payload.route[key] : s[key];
            })
        },
        reset(state) {
            const s = initialState()
            Object.keys(state).forEach(key => {
                state[key] = s[key]
            })
        },
    }

    actions = {
        reset({
            commit
        }) {
            commit("reset")
        },
    }
}
