<template>
  <div :class="`has-text-centered has-text-black is-size-${bodySize}`">
    <div class="margin-xl">
      <Picture
        :imageSrc="pictureUrl"
        :size="pictureSize"
        :borderSize="pictureBorderSize"
        :borderRadius="pictureBorderRadius"
        v-if="pictureUrl"
      />

      <div
        :class="`is-size-${titleSize} has-text-weight-semibold`"
        v-if="size !== 'big'"
      >
        <span class="has-text-weight-normal" v-if="showBy">by </span
        >{{ firstName }} {{ lastName }}
      </div>
      <h1
        :class="
          `is-size-${titleSize} is-size-${titleSize +
            1}-mobile has-text-weight-semibold text-compact`
        "
        v-if="size === 'big'"
      >
        <span class="has-text-weight-normal" v-if="showBy">by </span
        >{{ firstName }} {{ lastName }}
      </h1>

      <div>
        <span v-if="currentTitle">{{ currentTitle }}</span>
        <span
          class="is-inline-block"
          style="white-space: pre-wrap;"
          v-if="currentCompany"
        >
          at {{ currentCompany }}
        </span>
      </div>
      <div class="margin-sm" v-if="currentPlaceDesc">
        {{ currentPlaceDesc }}
      </div>
      <div class="margin-lg" v-if="contact">
        <a
          class="has-text-twitter"
          :href="contact.twitterUrl"
          target="_blank"
          v-if="contact.twitterUrl"
        >
          <font-awesome-icon
            class="social-icon"
            :icon="['fab', 'twitter']"
            size="lg"
          />
        </a>
        <a
          class="has-text-linkedin"
          :href="contact.linkedinUrl"
          target="_blank"
          v-if="contact.linkedinUrl"
        >
          <font-awesome-icon
            class="social-icon"
            :icon="['fab', 'linkedin']"
            size="lg"
          />
        </a>
        <a
          class="has-text-grey"
          :href="contact.websiteUrl"
          target="_blank"
          v-if="contact.websiteUrl"
        >
          <font-awesome-icon class="social-icon" icon="globe" size="lg" />
        </a>
      </div>
      <div class="interview has-text-grey has-text-left" v-if="interviewText">
        {{ interviewText }}
      </div>

      <a
        id="get-in-touch-button"
        class="button is-primary"
        :href="'mailto:' + email"
        v-if="email"
      >
        👋 Get in touch with {{ firstName }}
      </a>
    </div>
  </div>
</template>

<script>
import Picture from "@/atoms/Picture.vue";

export default {
  name: "AccountProfile",
  components: {
    Picture
  },
  props: {
    firstName: String,
    lastName: String,
    email: String,
    contact: Object,
    pictureUrl: String,
    currentTitle: String,
    currentCompany: String,
    currentPlaceDesc: String,
    interviewText: String,

    size: String,
    showBy: Boolean
  },
  computed: {
    pictureSize() {
      switch (this.size) {
        case "small":
          return "36px";
        case "big":
          return "100px";
        default:
          return "70px";
      }
    },
    pictureBorderSize() {
      switch (this.size) {
        case "small":
          return "2px";
        case "big":
          return "4px";
        default:
          return "3px";
      }
    },
    pictureBorderRadius() {
      switch (this.size) {
        case "small":
          return "4px";
        case "big":
          return "7px";
        default:
          return "6px";
      }
    },
    titleSize() {
      switch (this.size) {
        case "small":
          return 7;
        case "big":
          return 3;
        default:
          return 5;
      }
    },
    bodySize() {
      switch (this.size) {
        case "small":
          return 8;
        case "big":
          return 6;
        default:
          return 6;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.social-icon {
  margin-right: 6px;
}
#get-in-touch-button {
  margin-top: 0.5rem;
}
.interview {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-top: 0.2rem;
}
</style>
