<template>
  <div id="container">
    <b-tabs position="is-centered" type="is-toggle" v-model="activeTabIndex">
      <b-tab-item label="General">
        <section class="section top-section">
          <div class="container">
            <PanelTitle title="🤓 Basic profile" :changesSaved="changesSaved" />
            <div class="columns is-desktop is-variable is-3-desktop">
              <div class="column">
                <AccountProfileForm ref="profileForm" />
              </div>

              <div class="column">
                <Panel>
                  <div class="has-text-centered margin-xxl">
                    <div class="margin-md">
                      A stronger profile will lead to better matches
                    </div>
                    <CompletenessStars />
                  </div>

                  <b-input
                    class="margin-xxl"
                    v-model="email"
                    icon="envelope"
                    disabled
                  />

                  <b-switch
                    class="is-medium has-text-left is-size-5 is-size-6-mobile margin-xl"
                    v-model="active"
                  >
                    <div
                      class="has-text-weight-bold"
                      v-if="persistedAccountActive"
                    >
                      Your account is active
                    </div>
                    <div class="has-text-weight-bold" v-else>
                      Your account is inactive
                    </div>
                    <div v-if="persistedAccountActive">
                      You will be introduced to a new hacker every week
                    </div>
                    <div v-else>
                      We won't send you introduction emails anymore
                    </div>
                  </b-switch>

                  <div class="has-text-centered">
                    For any questions or inquiries regarding your account,
                    please
                    <a class="is-inline-block" :href="contactHref"
                      >contact us</a
                    >
                  </div>
                </Panel>
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="container">
            <PanelTitle title="🤝 Matching" :changesSaved="changesSaved" />
            <div class="columns is-desktop is-variable is-3-desktop">
              <div class="column">
                <Panel>
                  <AccountMatchingFormYourself />
                </Panel>
              </div>
              <div class="column">
                <Panel>
                  <AccountMatchingFormLookingFor />
                </Panel>
                <slot></slot>
              </div>
            </div>
          </div>
        </section>
      </b-tab-item>
      <b-tab-item label="My interview">
        <section class="section top-section">
          <div class="container">
            <PanelTitle title="✍️ Interview" :changesSaved="changesSaved">
              <div style="margin: 1rem 0 1.8rem 0;">
                <div class="margin-md has-text-weight-semibold">
                  Do you ever feel like you don't know how to start a
                  conversation with your match?
                </div>
                <div>
                  Telling others more about yourself is the best way to spark
                  interest and break the ice.
                </div>
              </div>
            </PanelTitle>
            <InterviewForm />
          </div>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import debounce from "lodash/debounce";
import { mapFields } from "@/lib/vuex-map-fields";
import Panel from "@/atoms/Panel.vue";
import PanelTitle from "@/atoms/PanelTitle.vue";
import AccountProfileForm from "@/organisms/AccountProfileForm.vue";
import AccountMatchingFormYourself from "@/molecules/AccountMatchingFormYourself.vue";
import AccountMatchingFormLookingFor from "@/molecules/AccountMatchingFormLookingFor.vue";
import CompletenessStars from "@/molecules/CompletenessStars.vue";
import InterviewForm from "@/organisms/InterviewForm.vue";

export default {
  name: "UserAccount",
  components: {
    Panel,
    PanelTitle,
    AccountProfileForm,
    AccountMatchingFormYourself,
    AccountMatchingFormLookingFor,
    CompletenessStars,
    InterviewForm
  },
  mounted() {
    const idx = this.tabs.findIndex(tab => tab === this.$route.hash);
    this.activeTabIndex = idx !== -1 ? idx : 0;
    this.$ga.page(this.$router);
  },
  watch: {
    activeTabIndex(index) {
      this.$router.push({
        name: this.$route.name,
        hash: this.tabs[index]
      });
    },
    async accountHash() {
      await this.onAccountChange();
    }
  },
  computed: {
    accountHash() {
      return (
        this.$store.getters["accountManagement/account/hash"] +
        this.$store.getters["accountManagement/accountProfile/hash"]
      );
    },
    changesSaved() {
      return (
        this.$store.getters["accountManagement/accountSaved"] &&
        this.$store.getters["accountManagement/accountProfileSaved"]
      );
    },
    contactHref() {
      return "mailto:" + this.$config.contactEmail;
    },
    persistedAccountActive() {
      return this.$store.state.accountManagement.account.active;
    },
    isValid() {
      return this.$refs.profileForm.isValid;
    },
    ...mapFields("accountManagement/account", ["email", "active"])
  },
  methods: {
    checkHtml5Validity() {
      this.$refs.profileForm.checkHtml5Validity();
    },
    onAccountChange: debounce(async function() {
      this.checkHtml5Validity();
      if (!this.isValid) {
        Toast.open({
          duration: 1000,
          message: "Oops. Looks like some mandatory information is missing.",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }
      await this.$store.dispatch({
        type: "accountManagement/updateUserAccount"
      });
    }, 600)
  },
  data() {
    return {
      tabs: ["#general", "#interview"],
      activeTabIndex: 0
    };
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height + 1.8rem;
  margin-bottom: 8rem;
}
.top-section {
  margin-top: 1.5rem;
}
</style>
