<template>
  <div class="has-text-left">
    <CheckboxButtons
      label="You would describe yourself as"
      :options="personaList"
      v-model="personas"
    />
    <CheckboxButtons
      label="Specialized in"
      :options="focusList"
      v-model="focuses"
      v-if="focusList.length"
    />
    <AccountContactSelector
      label="Communities you feel close to"
      :options="communityList"
      v-model="contact"
      :selectionValue="communities"
      @selectionInput="handleCommunitiesInput"
      selectionEnabled
      inputEnabled
      v-if="communityList.length"
    />
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import { personas } from "@/enums/personas";
import { focusesByPersona } from "@/enums/focuses";
import { communitiesByPersona } from "@/enums/communities";

import CheckboxButtons from "@/atoms/CheckboxButtons.vue";
import AccountContactSelector from "@/atoms/AccountContactSelector.vue";

export default {
  name: "AccountMatchingFormYourself",
  components: {
    CheckboxButtons,
    AccountContactSelector
  },
  methods: {
    handleCommunitiesInput(communities) {
      this.communities = communities;
    }
  },
  computed: {
    personaList() {
      return Object.values(personas);
    },
    focusList() {
      let focuses = [];
      for (const persona of Object.values(personas)) {
        for (const selected of this.personas) {
          if (persona.id === selected) {
            focuses = focuses.concat(focusesByPersona[selected]);
            break;
          }
        }
      }
      return focuses;
    },
    communityList() {
      let communities = [];
      for (const persona of Object.values(personas)) {
        for (const selected of this.personas) {
          if (persona.id === selected) {
            communities = communities.concat(communitiesByPersona[selected]);
            break;
          }
        }
      }
      return [...new Set(communities)];
    },
    ...mapFields("accountManagement/account", ["contact"]),
    ...mapFields("accountManagement/accountProfile", [
      "personas",
      "focuses",
      "communities"
    ])
  }
};
</script>

<style scoped>
</style>
