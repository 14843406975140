export default class {
	constructor(httpCli) {
		this.httpCli = httpCli
	}

	async getRoutes(body) {
		if (process.env.NODE_ENV === 'development') {
			return []
		}
		const res = await this.httpCli.post("/routes", body)
		return res.data
	}
}
