<template>
  <div id="container">
    <div class="section">
      <div class="container">
        <SignupPanel trackingLabel="signup_page" maxWidth="48rem" />
      </div>
    </div>
  </div>
</template>

<script>
import SignupPanel from "@/organisms/SignupPanel.vue";

export default {
  name: "Signup",
  components: { SignupPanel },
  mounted() {
    this.$ga.page(this.$router);
  }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height;
  margin-bottom: 12rem;
}
</style>
