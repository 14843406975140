<template functional>
  <div class="has-text-left margin-lg" style="margin-left: 0.6rem;">
    <div>
      <span class="is-size-3 is-size-4-mobile text-compact">
        {{ props.title }}
      </span>
      <span
        class="is-size-7 has-text-grey"
        style="margin-left: 0.1rem"
        v-show="props.changesSaved"
      >
        All changes saved
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PanelTitle",
  components: {},
  props: {
    title: String,
    changesSaved: Boolean
  }
};
</script>

<style scoped>
</style>
