<template>
  <div>
    <section class="hero" id="header-hero">
      <div class="hero-body">
        <div class="container">
          <div id="text-container">
            <img src="/static/heading.svg" id="heading-img" draggable="false" />

            <h1
              class="is-size-2 is-size-3-mobile has-text-weight-semibold text-compact margin-md"
            >
              ☕️ Always-on networking event for your Meetup
            </h1>

            <h2
              class="is-size-3 is-size-4-mobile text-compact has-text-left is-inline-block margin-xxxl"
            >
              Understand your members interests and challenges, add an easy way
              for them to help each other.
            </h2>

            <div class="has-text-grey is-size-6 margin-lg">
              Ready to try it out?
            </div>
            <div class="referlistcontainer">
              <div class="referlistinnercontainer">
                <input
                  type="text"
                  class="referlistemail"
                  id="referlistemail"
                  placeholder="email@domain.com"
                />
                <input
                  type="button"
                  class="referlistbutton"
                  id="referlistbutton"
                  value="Join waitlist"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero" id="details-hero">
      <div class="hero-body">
        <div class="container">
          <div style="margin-bottom:4rem">
            <Panel max-width="70rem">
              <div
                class="has-text-centered"
                id="panel"
                style="margin-bottom:2rem; "
              >
                <div
                  class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-xl"
                >
                  🤔 How does it work?
                </div>

                <div
                  class="is-size-4 is-size-5-mobile has-text-left is-inline-block"
                >
                  <div>
                    1. Sign up and add your community.
                  </div>
                  <div
                    class="margin-xl has-text-grey is-size-5 is-size-6-mobile"
                  >
                    e.g. Machine Learning Berlin Meetup.
                  </div>
                  <div class="margin-xl">
                    2. Invite your Meetup members to join.
                  </div>
                  <div>
                    3. Each member can select and add interests they would like
                    to discuss with others.
                  </div>
                  <div
                    class="margin-xl has-text-grey is-size-5 is-size-6-mobile"
                  >
                    e.g. Meeting NLP experts, Getting help with PyTorch, Looking
                    for a data scientist job.
                  </div>
                  <div class="margin-xl">
                    4. Members get paired based on their interests and receive
                    an introduction email.
                  </div>
                </div>
              </div>
            </Panel>
          </div>

          <div
            class="columns is-desktop is-variable is-centered is-8 is-multiline is-size-5 is-size-6-mobile"
            style="margin-bottom: 4rem;"
          >
            <div class="column desc is-6">
              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                🤝 Create more interactions
              </div>
              <div class="has-text-justified">
                Meetups are great but isn't it always hard to talk to everyone?
                The larger the event, the more we tend to stay in small groups.
                There's definitely someone someday that we missed and that we
                would have loved to meet.
              </div>
            </div>
            <div class="column desc is-6">
              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                💡 Understand your community's interests
              </div>
              <div class="has-text-justified">
                By using the platform, your attendees will over time build a
                ranked list of their interests. Use it to perfectly taylor your
                workshops and talks to the needs of the audience.
              </div>
            </div>
            <div class="column desc is-6">
              <div
                class="is-size-3 is-size-4-mobile text-compact has-text-weight-semibold margin-md"
              >
                ⛵️ Get more people onboard
              </div>
              <div class="has-text-justified">
                More interactions and more value drives word of mouth. Adding to
                it a boosted SEO and some internal Meetup recommendations: get
                even more people to join the party!
              </div>
            </div>
          </div>

          <div class="has-text-grey is-size-6 margin-lg">
            Ready to try it out?
          </div>
          <div class="referlistcontainer" style="margin-bottom: 10rem;">
            <div class="referlistinnercontainer">
              <input
                type="text"
                class="referlistemail"
                id="referlistemail2"
                placeholder="email@domain.com"
              />
              <input
                type="button"
                class="referlistbutton"
                id="referlistbutton2"
                value="Join waitlist"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import { mapFields, mapMultiRowFields } from "@/lib/vuex-map-fields";
import { personas } from "@/enums/personas";
import Panel from "@/atoms/Panel.vue";
// import Picture from "@/atoms/Picture.vue";
// import SignupFunnel from "@/organisms/SignupFunnel.vue";
// import AccountProfile from "@/organisms/AccountProfile.vue";

export default {
  name: "MeetupLandingPage",
  components: {
    Panel
    // Picture
    // SignupFunnel,
    // AccountProfile
  },
  mounted() {
    const script = document.createElement("script");
    script.setAttribute("src", "https://referlist.co/resources/referlist.js");
    script.onload = function() {
      window.referlist.initialize({ domain: "frenl" });
      window.referlist.initialize({
        domain: "frenl",
        emailId: "referlistemail2",
        buttonId: "referlistbutton2"
      }); // this will setup the second field
    };
    document.head.appendChild(script);

    this.$ga.page(this.$router);
  },
  computed: {
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    contactHref() {
      return "mailto:" + this.$config.contactEmail;
    }
  },
  methods: {
    trackSignupClick(label) {
      this.$ga.event("signup_link", "click", label);
    },
    trackShareClick(label) {
      this.$ga.event("share_button", "click", label);
    }
  },
  data: () => ({
    personaList: Object.values(personas)
  })
};
</script>

<style lang="scss" scoped>
#header-hero {
  padding: 0 0 4rem;
  background: white;
  border-bottom: 1px solid #d3d3d3;
}
#header-hero .hero-body {
  padding-bottom: 2.2rem;
}
#text-container {
  margin: 0.7rem 0 1rem;
}
#heading-img {
  max-height: 13rem;
  margin-bottom: 1.2rem;
}
.social-link {
  margin: 0.4rem;
  color: #3636367a;
}
.social-link:hover {
  color: #363636a2;
}
.share-button {
  margin: 10px;
}

#details-hero {
  margin: 2.5rem 0 1.5rem 0;
}
#intro-email-img {
  height: auto;
  max-width: 100%;
  padding: 0 2rem;
}
@media only screen and (max-width: $tablet) {
  #intro-email-img {
    padding: 0;
  }
}
@media only screen and (min-width: $tablet) and (max-width: $desktop) {
  #intro-email-img {
    max-width: 80%;
  }
}

.column.desc {
  padding: 2rem;
}

@media only screen and (min-width: $tablet) {
  #panel {
    padding: 3rem 4rem;
  }
}

// Waitlist
.referlistcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.referlistemail {
  width: 250px;
  margin-right: 10px;
  height: 45px;
  border-radius: 4px;
  border-color: black;
  font-size: 16px;
  padding-left: 10px;
}
.referlistbutton {
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: $primary-color;
  color: white;
}
.referlistinnercontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
