<template>
  <div class="has-text-centered">
    <Panel :maxWidth="maxWidth">
      <div class="has-text-centered margin-lg">
        <img
          class="margin-md"
          src="/static/signup.svg"
          id="image"
          draggable="false"
        />

        <h1 class="is-size-3 text-compact has-text-weight-semibold">
          Join us!
        </h1>
      </div>

      <div class="margin-xxl">
        <SignupFunnel :trackingLabel="trackingLabel" />
      </div>

      <div class="has-text-grey has-text-centered is-size-7">
        Already have an account?
        <span class="is-inline-block has-text-weight-semibold">
          <router-link :to="{ name: 'login' }">
            Log in
          </router-link>
        </span>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/atoms/Panel.vue";
import SignupFunnel from "@/organisms/SignupFunnel.vue";

export default {
  name: "SignupPanel",
  components: { Panel, SignupFunnel },
  props: {
    maxWidth: String,
    trackingLabel: String
  }
};
</script>

<style lang="scss" scoped>
#image {
  height: 10rem;
}
#signup-button-container {
  padding-top: 1rem;
}
</style>
