import { ToastProgrammatic as Toast } from 'buefy'
import { getField, updateField } from "@/lib/vuex-map-fields";
import Vue from "vue";

function initialState() {
  return {
    token: "",
    loginEmail: "",
    verifyAccountToken: "",

    signup: {
      inFlight: false,
      error: null
    },
    login: {
      inFlight: false,
      error: null
    },
    verifyAccount: {
      inFlight: false,
      error: null
    }
  }
}

export default class {
  namespaced = true

  modules = {}

  state = initialState()

  getters = {
    getField,

    loggedIn: state => state.token ? true : false,
    inFlight: state => state.signup.inFlight || state.login.inFlight || state.verifyAccount.inFlight,
    errors: (state) => [
      state.signup.error,
      state.login.error,
      state.verifyAccount.error,
    ].filter((val) => val ? true : false)
  }

  mutations = {
    updateField,

    signupRequest(state) {
      state.signup.inFlight = true
    },
    signupSuccess(state) {
      state.signup.inFlight = false
    },
    signupFailure(state, payload) {
      state.signup.inFlight = false
      state.signup.error = payload.error
    },

    loginRequest(state) {
      state.login.inFlight = true
    },
    loginSuccess(state) {
      state.login.inFlight = false
    },
    loginFailure(state, payload) {
      state.login.inFlight = false
      state.login.error = payload.error
    },

    verifyAccountRequest(state) {
      state.verifyAccount.inFlight = true
    },
    verifyAccountSuccess(state) {
      state.verifyAccount.inFlight = false
    },
    verifyAccountFailure(state, payload) {
      state.verifyAccount.inFlight = false
      state.verifyAccount.error = payload.error
    },

    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        if (key === "token") {
          return
        }
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.signup.error = null
      state.login.error = null
      state.verifyAccount.error = null
    },
  }

  actions = {
    async signup({
      commit,
      state,
      rootState
    }) {
      if (state.signup.inFlight) {
        return;
      }
      try {
        commit("signupRequest")
        await this.userMatcherCli.signup(
          rootState.accountManagement.account,
          rootState.accountManagement.accountProfile
        )
        commit("signupSuccess")

        this.router.push({ name: "verificationEmail" });
      } catch (err) {
        commit({
          type: "signupFailure",
          error: err
        })
      }
    },

    async login({
      commit,
      state,
    }) {
      if (state.login.inFlight) {
        return;
      }
      try {
        commit("loginRequest")
        await this.userMatcherCli.login(state.loginEmail)
        commit("loginSuccess")

        this.router.push({ name: "loginEmail" });
      } catch (err) {
        commit({
          type: "loginFailure",
          error: err
        })
      }
    },

    async logout({
      commit,
    }) {
      await this.router.push({ name: "landingPage" });
      Vue.nextTick(commit({
        type: "updateField",
        path: "token",
        value: ""
      }));
    },

    async verifyAccount({
      commit,
      state
    }) {
      if (state.verifyAccount.inFlight) {
        return;
      }
      const token = state.verifyAccountToken;
      try {
        commit("verifyAccountRequest")
        await this.userMatcherCli.verifyAccount(token)
        commit("verifyAccountSuccess")

        commit({
          type: "updateField",
          path: "token",
          value: token
        })

        Toast.open({
          duration: 5000,
          message: "Your email was successfully verified!",
          position: "is-bottom",
          type: "is-success"
        })
      } catch (err) {
        commit({
          type: "verifyAccountFailure",
          error: err
        })
      }
    },

    reset({
      commit
    }) {
      commit("reset")
    },
  }
}
