import pick from "lodash/pick";
import gql from "@/lib/gqlu";

export default class {
  constructor(httpCli) {
    this.httpCli = httpCli
  }

  // PUBLIC

  signup(account, profile) {
    return gql(this.httpCli, `
      mutation Signup($input: SignupInput!) {
        signup(input: $input)
      }
    `, {
      input: {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        accountProfile: {
          ...pick(profile,
            "currentTitle",
            "currentCompany",
            "personas",
            "focuses",
            "personaAspirations",
            "focusAspirations",
            "communities",
            "interview"
          ),
          currentPlaceId: profile.currentPlace.id,
        }
      }
    })
  }

  login(email) {
    return gql(this.httpCli, `
      mutation Login($email: String!) {
        login(email: $email)
      }
    `, {
      email: email,
    })
  }

  verifyAccount(token) {
    return gql(this.httpCli, `
      mutation VerifyAccount($token: String!) {
        verifyAccount(token: $token)
      }
    `, {
      token: token,
    })
  }

  getStaticInterviewByTitleSlug(slug) {
    return gql(this.httpCli, `
      query GetStaticInterviewByTitleSlug($slug: String!) {
        staticInterview: getStaticInterviewByTitleSlug(slug: $slug) {
          title
          titleSlug
          illustrationUrl
          headlineHtml
          projectName
          projectUrl
          actionName
          actionUrl
          interviewHtml
          matchingRound {
            number
            createdDate
          }
          account {
            firstName
            lastName
            email
            contact {
              twitterUrl
              linkedinUrl
              websiteUrl
            }
            pictureUrl
            accountProfile {
              currentTitle
              currentCompany
              currentPlace {
                description
              }
            }
          }
        }
      }
    `, {
      slug: slug
    }, null, true)
  }

  listStaticInterviews() {
    return gql(this.httpCli, `
      {
        staticInterviews: listStaticInterviews {
          id
          title
          titleSlug
          illustrationUrl
          headlineHtml
          projectName
          projectUrl
          matchingRound {
            number
            createdDate
          }
          account {
            firstName
            lastName
            contact {
              twitterUrl
              linkedinUrl
              websiteUrl
            }
            pictureUrl
            accountProfile {
              currentTitle
              currentCompany
              currentPlace {
                description
              }
            }
          }
        }
      }
    `, {}, null, true)
  }

  autocompleteLocation(location) {
    return gql(this.httpCli, `
      query AutocompleteLocation($location: String!) {
        autocompletePredictions: autocompleteLocation(location: $location) {
          description
          placeId
        }
      }
    `, {
      location: location,
    }, null, true)
  }

  listTopAccounts(currentPlaceId, personas, focuses) {
    return gql(this.httpCli, `
      query ListTopAccounts($currentPlaceId: String!, $personas: [String!]!, $focuses: [String!]!) {
        accounts: listTopAccounts(currentPlaceId: $currentPlaceId, personas: $personas, focuses: $focuses) {
          id
          firstName
          pictureUrl
          accountProfile {
            currentTitle
            currentCompany
            currentPlace {
              description
            }
            interviewText
          }
        }
      }
    `, {
      currentPlaceId: currentPlaceId,
      personas: personas,
      focuses: focuses,
    }, null, true)
  }

  // PRIVATE

  getUserAccount(token) {
    return gql(this.httpCli, `
      {
        account: getUserAccount{
          firstName
          lastName
          pictureUrl
          contact{
            twitter
            linkedin
            website

            github
            indiehackers
            producthunt
            dribbble
            thedesignership
          }
          email
          active
          onboarded
          accountProfile{
            currentTitle
            currentCompany
            currentPlace {
              id
              description
            }
            personas
            focuses
            personaAspirations
            focusAspirations
            communities
            interview {
              questionTechnicalName
              question
              answerMd
            }
          }
        }
      }
    `, {}, token)
  }


  updateUserAccount(token, account, accountProfile) {
    const interview = accountProfile.interview.reduce((memo, item) => {
      if (!item.answerMd) {
        return memo
      }
      item = { ...item }
      delete item.question
      return memo.concat(item)
    }, [])

    return gql(this.httpCli, `
      mutation UpdateUserAccount(
        $account: AccountInput!,
        $accountProfile: AccountProfileInput!
      ) {
        updateUserAccount(input: $account)
        updateUserAccountProfile(input: $accountProfile)
      }
    `, {
      account: pick(account,
        "firstName",
        "lastName",
        "contact",
        "active",
        "onboarded",
      ),
      accountProfile: {
        ...pick(accountProfile,
          "currentTitle",
          "currentCompany",
          "personas",
          "focuses",
          "personaAspirations",
          "focusAspirations",
          "communities"
        ),
        currentPlaceId: accountProfile.currentPlace.id,
        interview: interview,
      },
    }, token)
  }

  getUserStaticInterview(token) {
    return gql(this.httpCli, `
      {
        staticInterview: getUserStaticInterview {
          id
          title
          titleSlug
          illustrationUrl
          headlineMd
          headlineHtml
          projectName
          projectUrl
          actionName
          actionUrl
          ready
          matchingRound {
            number
            createdDate
          }
          interviewMd
          interviewHtml
        }
      }
    `, {}, token)
  }

  getUserStaticInterviewHtml(token) {
    return gql(this.httpCli, `
      {
        staticInterview: getUserStaticInterview {
          interviewHtml
        }
      }
    `, {}, token)
  }

  updateUserStaticInterview(token, staticInterview) {
    return gql(this.httpCli, `
      mutation UpdateUserStaticInterview(
        $staticInterview: StaticInterviewInput!,
      ) {
        updateUserStaticInterview(input: $staticInterview)
      }
    `, {
      staticInterview: pick(staticInterview,
        "title",
        "illustrationUrl",
        "quote",
        "headlineMd",
        "projectName",
        "projectUrl",
        "actionName",
        "actionUrl",
        "ready",
        "interviewMd",
      ),
    }, token)
  }
}
