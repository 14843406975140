<template>
  <div class="text-area">
    <div class="label" v-if="label">
      {{ label }}
      <span class="has-text-grey has-text-weight-light" v-if="optional"
        >(optional)</span
      >
    </div>
    <b-field>
      <b-input
        :value="value"
        @input="$emit('input', $event)"
        type="textarea"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :required="required"
        ref="text"
        :style="style"
      />
    </b-field>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    label: String,
    placeholder: String,
    value: String,
    maxLength: Number,
    optional: Boolean,
    required: Boolean,
    height: Number
  },
  computed: {
    isValid() {
      return this.$refs.text.isValid;
    },
    style() {
      if (!this.height) {
        return "";
      }
      return `height:${this.height}px`;
    }
  },
  methods: {
    checkHtml5Validity() {
      this.$refs.text.checkHtml5Validity();
    }
  }
};
</script>

<style scoped>
.text-area {
  margin-bottom: -8px;
}

.text-area /deep/ textarea {
  height: 100%;
  max-height: 100%;
}
</style>
