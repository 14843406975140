<template>
  <div id="container">
    <MessageBox
      message="We just sent you an email with a magic link. Click on it to log in."
    />
  </div>
</template>

<script>
import MessageBox from "@/organisms/MessageBox.vue";

export default {
  name: "LoginEmail",
  mounted() {
    this.$ga.page(this.$router);
  },
  components: { MessageBox }
};
</script>

<style lang="scss" scoped>
#container {
  padding-top: $nav-bar-height;
  margin-bottom: 12rem;
}
</style>
