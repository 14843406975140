<template functional>
  <img
    :class="{ clickable: props.clickable }"
    :src="props.imageSrc"
    :style="
      `max-width:${props.size}; max-height:${props.size};
      border: ${props.borderSize} solid #0000000f;
      border-radius:${props.borderRadius};`
    "
    draggable="false"
  />
</template>

<script>
export default {
  name: "Picture",
  props: {
    imageSrc: String,
    size: String,
    borderSize: String,
    borderRadius: String,
    clickable: Boolean
  }
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.clickable:hover {
  filter: brightness(85%);
}
</style>
