<template>
  <footer id="footer" class="has-text-white">
    <div id="footer-content">
      <div>
        Frenl was built from blood, sweat and coffee over week-ends of work by
        the whole Frenl team composed of
        <span class="is-inline-block">
          <a href="https://twitter.com/FabienHerfray" target="_blank">
            Fabien Herfray
          </a> </span
        >.
      </div>
      <div class="separator" />
      <div>
        <span class="is-inline-block">
          <a href="https://delesign.com?utm_source=frenl" target="_blank">
            Illustrations
          </a>
        </span>

        -

        <span class="is-inline-block">
          <a
            href="https://www.iubenda.com/privacy-policy/66655023?utm_source=frenl"
            target="_blank"
          >
            Impressum
          </a>
        </span>

        -

        <span class="is-inline-block">
          <a
            href="https://www.iubenda.com/privacy-policy/66655023/legal?utm_source=frenl"
            target="_blank"
          >
            Privacy Policy
          </a>
        </span>

        -

        <span class="is-inline-block">
          <a :href="contactHref">
            Contact
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    contactHref() {
      return "mailto:" + this.$config.contactEmail;
    }
  }
};
</script>

<style lang="scss" scoped>
#footer {
  background-color: #212833;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: $footer-height;
}
#footer-content {
  padding: 1.2rem 1rem 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.separator {
  width: 16px;
  margin: 0.5rem;
  border-top: 1px solid #bebebe;
}
</style>
