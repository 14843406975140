<template>
  <div class="has-text-left">
    <AccountContactSelector
      label="Links"
      :options="socialList"
      v-model="contact"
      inputEnabled
    />
  </div>
</template>

<script>
import { mapFields } from "@/lib/vuex-map-fields";
import { social } from "@/enums/social.js";

import AccountContactSelector from "@/atoms/AccountContactSelector.vue";

export default {
  name: "AccountSocialForm",
  components: {
    AccountContactSelector
  },
  computed: {
    ...mapFields("accountManagement/account", ["contact"])
  },
  data: () => ({
    socialList: Object.values(social)
  })
};
</script>

<style scoped>
</style>
