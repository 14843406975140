<template>
  <div id="app">
    <NavBar v-if="currentRoute !== 'meetupLandingPage'" />
    <div id="content-wrap">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/organisms/NavBar.vue";
import Footer from "@/organisms/Footer.vue";

export default {
  name: "app",
  components: {
    NavBar,
    Footer
  },
  computed: {
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    errors() {
      return this.$store.getters["errors"];
    },
    currentRoute() {
      return this.$route.name;
    }
  },
  watch: {
    loggedIn() {
      this.$store.dispatch({ type: "reset" });
    },
    errors(newErrors) {
      if (newErrors.length == 0) {
        return;
      }
      this.$store.dispatch({ type: "cleanupErrors" });

      const self = this;
      newErrors.forEach(function(error) {
        let message = "An unexpected error occured. Please retry later.";
        if (error.gqlErrors && error.gqlErrors.length > 0) {
          if (
            error.gqlErrors[0].extensions.errorCode == "AUTHORIZATION_REQUIRED"
          ) {
            self.$store.dispatch("authentication/logout");
            self.$buefy.toast.open({
              duration: 3000,
              message: "Session expired. Please log in again.",
              position: "is-bottom",
              type: "is-danger"
            });
            return;
          }

          switch (error.gqlErrors[0].path.join(".")) {
            case "login":
              if (error.gqlErrors[0].extensions.errorCode == "NOT_FOUND") {
                message =
                  "Oops. Looks like we couldn't find you in our database.";
              }
              break;

            default:
              message = error.gqlErrors[0].message;
              break;
          }
        }
        self.$buefy.toast.open({
          duration: 3000,
          message: message,
          position: "is-bottom",
          type: "is-danger",
          queue: false
        });

        if (error instanceof Error) {
          console.error(error);
        } else {
          console.error(JSON.parse(JSON.stringify(error)));
        }
      });
    }
  }
};
</script>

<style lang="scss">
// Setup $sizes to use as bulma classes (e.g. 'is-size-4')
$size-1: 40px;
$size-2: 34px;
$size-3: 28px;
$size-4: 24px;
$size-5: 21px;
$size-6: 17px;
$size-7: 15px;
$size-8: 13px;
$body-font-size: 17px;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: $primary-color;
$twitter: #1b95e0;
$facebook: #3b5999;
$linkedin: #2867b2;
$reddit: #ff4301;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black-color
  ),
  "black": (
    $black-color,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    findColorInvert($primary)
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    findColorInvert($twitter)
  ),
  "facebook": (
    $facebook,
    findColorInvert($facebook)
  ),
  "linkedin": (
    $linkedin,
    findColorInvert($linkedin)
  ),
  "reddit": (
    $reddit,
    findColorInvert($reddit)
  ),
  "grey": (
    $grey,
    findColorInvert($grey)
  )
);

// Links
$link: $primary-color;
$link-focus-border: $primary-color;

// Some general overrides
$navbar-height: $nav-bar-height;
$section-padding: 0;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Hard overrides
.textarea::placeholder,
.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #afafaf;
  opacity: 1; /* Firefox */
}
@media only screen and (max-width: $desktop) {
  .navbar-menu {
    box-shadow: 0 12px 8px rgba(10, 10, 10, 0.06) !important;
  }
}
body.has-navbar-fixed-top {
  padding-top: 0;
}
.navbar-menu a.navbar-link,
.navbar-menu a.navbar-item {
  padding-top: 0.6rem;
}
strong {
  font-weight: 600 !important;
}
a:hover {
  color: $primary-color-hover;
}
.section {
  margin: 1.8rem 0rem;
}
@media only screen and (min-width: $tablet) {
  .section {
    margin: 2rem 1.5rem;
  }
}
@media only screen and (max-width: $tablet) {
  .hero-body {
    padding: 2rem 1.2rem;
  }
}
.switch + .switch {
  margin-left: 0;
}

nav.steps {
  margin-bottom: 1rem;
}
.b-steps .steps + section.step-content {
  padding: 1rem 0;
}
.b-tabs .tab-content {
  padding: 0;
}

.button.is-small {
  border-radius: 3px;
}

.checkbox-buttons .field.has-addons .control .button,
.radio-buttons .field.has-addons .control .button {
  border-radius: 4px !important;
}
.checkbox-buttons .field.has-addons .control .button,
.radio-buttons .field.has-addons .control .button {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.checkbox-buttons .field.has-addons,
.radio-buttons .field.has-addons {
  flex-wrap: wrap;
  margin-bottom: -0.5rem;
}
.checkboxes .b-checkbox.checkbox {
  white-space: nowrap;
  margin-left: 0;
}

.button.is-twitter,
.button.is-facebook,
.button.is-linkedin,
.button.is-reddit {
  margin: 0.2rem;
}

// New classes
.divider {
  border-top: 1px solid #e4e4e4;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}
.column.center-content {
  display: flex;
  justify-content: center;
}
.text-compact {
  letter-spacing: -0.02em;
}
.highlighted-block {
  border-left: 4px solid #ff6c28;
  padding-left: 10px;
}
.margin-xs:not(:last-child) {
  margin-bottom: 3px;
}
.margin-sm:not(:last-child) {
  margin-bottom: 6px;
}
.margin-md:not(:last-child) {
  margin-bottom: 8px;
}
.margin-lg:not(:last-child) {
  margin-bottom: 16px;
}
.margin-xl:not(:last-child) {
  margin-bottom: 30px;
}
.margin-xxl:not(:last-child) {
  margin-bottom: 40px;
}
.margin-xxxl:not(:last-child) {
  margin-bottom: 64px;
}

// Form components padding
.field:not(:last-child),
.checkbox-buttons:not(:last-child),
.checkboxes:not(:last-child),
.radio-buttons:not(:last-child) {
  margin-bottom: 1rem;
}

// Component
html,
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
#app {
  position: relative;
  min-height: 100vh;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  line-height: 1.47;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $black-color;
  background-color: $background-color;
  background-image: $background-image;
}
#content-wrap {
  padding-bottom: $footer-height;
}
// Offset for anchor links.
:target:before {
  content: "";
  display: block;
  height: $nav-bar-height + 1rem; /* fixed header height*/
  margin: -($nav-bar-height + 1rem) 0 0; /* negative fixed header height */
}
</style>
