export const social = {
	"twitter": {
		id: "twitter",
		name: "Twitter",
		externalIdDescription: "https://twitter.com/",
		externalIdPlaceholder: "e.g. jack",
	},
	"linkedin": {
		id: "linkedin",
		name: "LinkedIn",
		externalIdDescription: "https://www.linkedin.com/in/",
		externalIdPlaceholder: "e.g. reidhoffman",
	},
	"website": {
		id: "website",
		name: "Website",
		externalIdDescription: "https://",
		externalIdPlaceholder: "e.g. www.frenl.com",
	}
}
