export const communities = {
	"100daysofcode": {
		id: "100daysofcode",
		name: "#100DaysOfCode",
		personas: ["developer"],
	},
	"github": {
		id: "github",
		name: "GitHub",
		externalIdDescription: "https://github.com/",
		externalIdPlaceholder: "e.g. defunkt",
		personas: ["developer"],
	},

	"producthunt": {
		id: "producthunt",
		name: "Product Hunt",
		externalIdDescription: "https://www.producthunt.com/@",
		externalIdPlaceholder: "e.g. rrhoover",
		personas: ["maker", "developer", "designer"],
	},
	"indiehackers": {
		id: "indiehackers",
		name: "Indie Hackers",
		externalIdDescription: "https://www.indiehackers.com/",
		externalIdPlaceholder: "e.g. csallen",
		personas: ["maker", "developer", "designer"],
	},

	"dribbble": {
		id: "dribbble",
		name: "Dribbble",
		externalIdDescription: "https://dribbble.com/",
		externalIdPlaceholder: "e.g. simplebits",
		personas: ["designer"],
	},
	"thedesignership": {
		id: "thedesignership",
		name: "The Designership",
		externalIdDescription: "Slack @",
		externalIdPlaceholder: "e.g. mizko",
		personas: ["designer"],
	},
}

export const communitiesByPersona = Object.values(communities).reduce(function (
	result,
	community
) {
	for (const persona of community.personas) {
		let communities = result[persona];
		if (communities) {
			communities.push(community)
		} else {
			communities = [community]
		}
		result[persona] = communities
	}
	return result;
},
	{});
