export default function (environment) {
  switch (environment) {
    case "production":
      return new Config(
        false,
        "https://api.frenl.com",
        "hello@frenl.com",
      )
    default:
      return new Config(
        true,
        "http://localhost:8080",
        "hello@frenl.com",
      )
  }
}

class Config {
  constructor(strictStore, graphqlServerUrl, contactEmail) {
    this.strictStore = strictStore
    this.graphqlServerUrl = graphqlServerUrl
    this.contactEmail = contactEmail
    this.analyticsID = "UA-119163111-2"
  }
}
