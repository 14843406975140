import { getField, updateField } from "@/lib/vuex-map-fields";
import StaticInterviews from "@/resources/static-interviews-store"
import StaticInterview from "@/resources/static-interview-store"
import Account from "@/resources/account-store"
import AccountProfile from "@/resources/account-profile-store"

function initialState() {
  return {
    titleSlug: "",
    savedUserStaticInterviewHash: "",

    getStaticInterviewByTitleSlug: {
      inFlight: false,
      error: null
    },
    listStaticInterviews: {
      inFlight: false,
      error: null
    },
    getUserStaticInterview: {
      inFlight: false,
      error: null
    },
    refreshUserStaticInterviewHtml: {
      inFlight: false,
      error: null
    },
    updateUserStaticInterview: {
      inFlight: false,
      error: null
    },
  }
}

export default class {
  namespaced = true

  modules = {
    staticInterview: new StaticInterview(),
    account: new Account(),
    accountProfile: new AccountProfile(),

    staticInterviews: new StaticInterviews(),

    userStaticInterview: new StaticInterview(),
  }

  state = initialState()

  getters = {
    getField,

    userStaticInterviewSaved: (state, getters) => getters["userStaticInterview/hash"] === state.savedUserStaticInterviewHash,

    inFlight: state => state.getStaticInterviewByTitleSlug.inFlight || state.listStaticInterviews.inFlight ||
      state.getUserStaticInterview.inFlight || state.refreshUserStaticInterviewHtml.inFlight || state.updateUserStaticInterview.inFlight,
    errors: state => [
      state.getStaticInterviewByTitleSlug.error,
      state.listStaticInterviews.error,
      state.getUserStaticInterview.error,
      state.refreshUserStaticInterviewHtml.error,
      state.updateUserStaticInterview.error
    ].filter((val) => val ? true : false),
  }

  mutations = {
    updateField,

    getStaticInterviewByTitleSlugRequest(state) {
      state.getStaticInterviewByTitleSlug.inFlight = true
    },
    getStaticInterviewByTitleSlugSuccess(state) {
      state.getStaticInterviewByTitleSlug.inFlight = false
    },
    getStaticInterviewByTitleSlugFailure(state, payload) {
      state.getStaticInterviewByTitleSlug.inFlight = false
      state.getStaticInterviewByTitleSlug.error = payload.error
    },

    listStaticInterviewsRequest(state) {
      state.listStaticInterviews.inFlight = true
    },
    listStaticInterviewsSuccess(state) {
      state.listStaticInterviews.inFlight = false
    },
    listStaticInterviewsFailure(state, payload) {
      state.listStaticInterviews.inFlight = false
      state.listStaticInterviews.error = payload.error
    },

    getUserStaticInterviewRequest(state) {
      state.getUserStaticInterview.inFlight = true
    },
    getUserStaticInterviewSuccess(state) {
      state.getUserStaticInterview.inFlight = false
    },
    getUserStaticInterviewFailure(state, payload) {
      state.getUserStaticInterview.inFlight = false
      state.getUserStaticInterview.error = payload.error
    },

    refreshUserStaticInterviewHtmlRequest(state) {
      state.refreshUserStaticInterviewHtml.inFlight = true
    },
    refreshUserStaticInterviewHtmlSuccess(state) {
      state.refreshUserStaticInterviewHtml.inFlight = false
    },
    refreshUserStaticInterviewHtmlFailure(state, payload) {
      state.refreshUserStaticInterviewHtml.inFlight = false
      state.refreshUserStaticInterviewHtml.error = payload.error
    },

    updateUserStaticInterviewRequest(state) {
      state.updateUserStaticInterview.inFlight = true
    },
    updateUserStaticInterviewSuccess(state) {
      state.updateUserStaticInterview.inFlight = false
    },
    updateUserStaticInterviewFailure(state, payload) {
      state.updateUserStaticInterview.inFlight = false
      state.updateUserStaticInterview.error = payload.error
    },


    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    cleanupErrors(state) {
      state.getStaticInterviewByTitleSlug.error = null
      state.listStaticInterviews.error = null
      state.getUserStaticInterview.error = null
      state.refreshUserStaticInterviewHtml.error = null
      state.updateUserStaticInterview.error = null
    },
  }

  actions = {
    async getStaticInterviewByTitleSlug({
      commit,
      state
    }) {
      if (state.getStaticInterviewByTitleSlug.inFlight) {
        return;
      }
      try {
        commit("getStaticInterviewByTitleSlugRequest")
        const res = await this.userMatcherCli.getStaticInterviewByTitleSlug(state.titleSlug)
        commit("getStaticInterviewByTitleSlugSuccess")

        commit({
          type: "staticInterview/set",
          staticInterview: res.staticInterview,
        })
        commit({
          type: "account/set",
          account: res.staticInterview.account,
        })
        commit({
          type: "accountProfile/set",
          accountProfile: res.staticInterview.account.accountProfile,
        })
      } catch (err) {
        commit({
          type: "getStaticInterviewByTitleSlugFailure",
          error: err
        })
      }
    },

    async listStaticInterviews({
      commit,
      state
    }) {
      if (state.listStaticInterviews.inFlight) {
        return;
      }
      try {
        commit("listStaticInterviewsRequest")
        const res = await this.userMatcherCli.listStaticInterviews()
        commit("listStaticInterviewsSuccess")

        commit({
          type: "staticInterviews/set",
          staticInterviews: res.staticInterviews,
        })
      } catch (err) {
        commit({
          type: "listStaticInterviewsFailure",
          error: err
        })
      }
    },

    async getUserStaticInterview({
      commit,
      state,
      rootState,
      getters
    }) {
      if (state.getUserStaticInterview.inFlight) {
        return;
      }
      try {
        commit("getUserStaticInterviewRequest")
        const res = await this.userMatcherCli.getUserStaticInterview(rootState.authentication.token)
        commit("getUserStaticInterviewSuccess")

        commit({
          type: "userStaticInterview/set",
          staticInterview: res.staticInterview,
        })
        commit({
          type: "updateField",
          path: "savedUserStaticInterviewHash",
          value: getters["userStaticInterview/hash"]
        });
      } catch (err) {
        commit({
          type: "getUserStaticInterviewFailure",
          error: err
        })
      }
    },

    async refreshUserStaticInterviewHtml({
      commit,
      state,
      rootState
    }) {
      if (state.refreshUserStaticInterviewHtml.inFlight) {
        return;
      }
      try {
        commit("refreshUserStaticInterviewHtmlRequest")
        const res = await this.userMatcherCli.getUserStaticInterviewHtml(rootState.authentication.token)
        commit("refreshUserStaticInterviewHtmlSuccess")

        commit({
          type: "userStaticInterview/updateField",
          path: "interviewHtml",
          value: res.staticInterview.interviewHtml
        });
      } catch (err) {
        commit({
          type: "refreshUserStaticInterviewHtmlFailure",
          error: err
        })
      }
    },

    async updateUserStaticInterview({
      commit,
      state,
      rootState,
      getters
    }) {
      if (state.updateUserStaticInterview.inFlight) {
        return;
      }
      try {
        commit("updateUserStaticInterviewRequest")
        await this.userMatcherCli.updateUserStaticInterview(rootState.authentication.token, state.userStaticInterview)
        commit("updateUserStaticInterviewSuccess")

        commit({
          type: "updateField",
          path: "savedUserStaticInterviewHash",
          value: getters["userStaticInterview/hash"]
        });
      } catch (err) {
        commit({
          type: "updateUserStaticInterviewFailure",
          error: err
        })
      }
    },


    reset({
      commit,
      dispatch
    }) {
      commit("reset")
      dispatch("staticInterview/reset")
      dispatch("account/reset")
      dispatch("accountProfile/reset")
      dispatch("staticInterviews/reset")
      dispatch("userStaticInterview/reset")
    },
  }
}
