<template>
  <Panel :maxWidth="maxWidth">
    <div
      class="margin-xl"
      :key="i.questionTechnicalName"
      v-for="i in interview"
    >
      <TextArea
        :label="i.question"
        placeholder="Markdown formatted answer"
        :maxLength="1000"
        v-model="i.answerMd"
      />
    </div>
  </Panel>
</template>

<script>
import { mapMultiRowFields } from "@/lib/vuex-map-fields";

import Panel from "@/atoms/Panel.vue";
import TextArea from "@/atoms/TextArea.vue";

export default {
  name: "InterviewForm",
  components: {
    Panel,
    TextArea
  },
  props: {
    maxWidth: String
  },
  computed: {
    ...mapMultiRowFields("accountManagement/accountProfile", ["interview"])
  },
  methods: {}
};
</script>

<style scoped>
</style>
