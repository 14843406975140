export const personas = {
	developer: {
		id: "developer",
		name: "Developer",
		namePlural: "Developers",
		description: "e.g. Coder, Software Engineer",
	},
	maker: {
		id: "maker",
		name: "Maker",
		namePlural: "Makers",
		description: "e.g. Entrepreneur, Indie Hacker",
	},
	designer: {
		id: "designer",
		name: "Designer",
		namePlural: "Designers",
		description: "e.g. Web Designer, Brand Strategist",
	},
}
